import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable ,  Subject } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";


import * as firebase from 'firebase';

// declare let ga:Function;
// declare let FS:any;

@Injectable()
export class AuthService {
    currentUser: Observable<firebase.User>;
    uid = "";
    isGated = false;
    isLoggedIn = false;
    reAuthRequested = false;
    reAuthReturnURL = "";
    authInfo = "";

    lat = "";
    lng = "";
    address = "";

    redirectUrl: string;

    constructor(public firebaseAuth: AngularFireAuth,
                private dbfs: AngularFirestore,
                private router:Router) 
    {
        this.currentUser = firebaseAuth.authState;
        firebase.auth().onAuthStateChanged((user) => {
            console.log("AUTH: onAuthStateChanged!",user)
            if(user){
                // do user stuff
                this.isLoggedIn = true;
                this.uid = user.uid;
                
                // if enabling locaton
                // navigator.geolocation.getCurrentPosition(this.displayLocationInfo.bind(this));
                
                // redirect if logged in
                // if (this.router.url.includes('login')) { this.router.navigate(['/app']);}
                
            } else {
                // no logged in user
                this.uid = null;
                this.isLoggedIn = false
            }
        });

    }

    getLogInStatus() {
        return this.isLoggedIn;
    }
    
    displayLocationInfo(position) {
        let userID = this.uid;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        // console.log(position)
        // this.getAddress(Number(this.lat),Number(this.lng));
        let geocoder = new google.maps.Geocoder;
        let latlng = {lat: Number(this.lat), lng: Number(this.lng)};
        geocoder.geocode({'location': latlng}, function(results,status) {
            // console.log(results,status)
            if (results[0]) {
                this.address = results[0].formatted_address;
            } else {
                console.log('No results found');
                this.address = "geocode error"
            }
        });
            this.logSession(userID,this.lat,this.lng)
    }

    logSession(userID,lat,lng) {
        // console.log("logSession!",lat,lng);
        const dbfs = firebase.firestore();

        let userRef = this.dbfs.doc(`/users/${userID}`);
        let logRef = dbfs.collection('users').doc(userID).collection('logs').doc();
        let timestamp = Date.now();
        logRef.set({
            "title":"Session",
            "lat":lat,
            "lng":lng,
            "timestamp_created":timestamp
        }).then(logResult => {
            setTimeout(function(){
                // console.log("WE WAIT BEFORE LOGGING ADDRESS",this.address);
                logRef.update({
                    "location":this.address
                });
                userRef.update({
                    "last_loc":this.address
                });
            }, 3000);
        })
    }


    loginWithEmail(email:string = "", password:string = ""):Promise<any> {
        console.log("Auth Service: Logging In...");
        
        var returnPromise = firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password)
        /*.then(user => {
            console.log("AUTH:EMAIL: Successfully logged in! ", user);
            this.isLoggedIn = true;
        })
        .catch(error => {
            console.log("LOGIN:EMAIL: Error: " + error.message);
        });*/
        
        return returnPromise;
        //return Observable.of(true).delay(1).do(val => this.isLoggedIn = true);
    }
    
    loginWithUserImpersonation(token:string = ""):Promise<any> {
        console.log("Auth Service: Logging In...");
        
        var returnPromise = firebase.auth().signInWithCustomToken(token)
        .then(user => {
            console.log("AUTH:EMAIL: Successfully logged in! ", user);
            this.isLoggedIn = true;
        })
        .catch(error => {
            console.log("LOGIN:EMAIL: Error: " + error.message);
        });
        


        return returnPromise;
        //return Observable.of(true).delay(1).do(val => this.isLoggedIn = true);
    }

    logout():any {
        this.isLoggedIn = false;
        // this.userService.reset();
        // this.transactionService.reset();
        var returnPromise = firebase.auth().signOut()
            .then(result => {
                console.log("USER LOGGED OUT");
                
                // clear all services, observables and connections to firebase write data

                // in meantime reload page to clear connections on logout
                //window.location.reload();
                // window.localStorage.removeItem("firebase:session::bmt-dev-2");
            });

        return returnPromise;
    }

    sendPasswordResetEmail(email:string):Promise<any> {
    
        return firebase.auth().sendPasswordResetEmail(email);
        // 	.then(function() {
        //   // Email sent.
        //   console.log("Password reset email sent");
        //   return Observable.of(true);
        // }).catch(function(error) {
        //   // An error happened.
        //   console.log("Error sending password reset email", error);
        //   return Observable.of(false);
        // }));
    }

    /**
     * Signup User with Email / Password Credentials
     */
    createUserEmail(email, password):any {
        console.log("creating new user",email,password)
        var returnPromise;
        return returnPromise = firebase.auth().createUserWithEmailAndPassword(email, password);

    }

    fetchSignInMethodsForEmail(email:string):Promise<any> {
        return firebase.auth().fetchSignInMethodsForEmail(email);
    }

    parseServerError(error):string {
        let message:string = "";
        let code = error.code;
        // complete firebase list: https://firebase.google.com/docs/reference/js/firebase.auth.Error
        switch(code) {
            case "auth/user-not-found":
                message = "This account was not found in the system. Please make sure your credentials are correct."
                break;
            case "auth/requires-recent-login":
                message = "You must re-authenticate before changing your email or password.";
                break;
            case "auth/email-already-in-use":
                message = "Email already exists in database.";
                break;
            case "auth/user-disabled":
                message = "Your account has been disabled by an administrator.";
                break;           
            case "auth/weak-password":
                message = "Please pick a stronger password.";
                break;
            case "auth/user-token-expired":
                message = "Your session has expired. Please log back in.";
                break;
            case "auth/invalid-user-token":
                message = "Your auth token is no longer valid. Please login again."
                break;
            case "auth/network-request-failed":
                message = "There was a network error while communicating with the server."
                break;
            case "auth/wrong-password":
                message = "Invalid password, please try again.";
                break;
            default:
                message = error.message;
                break;
        }

        return message;
    }

    get user() { return firebase.auth().currentUser }
    
    get userID() { return firebase.auth().currentUser ? firebase.auth().currentUser.uid : undefined}
  
    get loggedIn() { return (firebase.auth().currentUser) ? true : false; }
}