import { Component, Inject, OnInit, ViewChild, NgZone, Input, Output, ElementRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ImageDialogComponent } from './image-dialog.component';


@Component({
  selector: 'image-component',
  templateUrl: 'image.component.html',
  styleUrls: ['./image.component.scss']
  // entryComponents: [ImageDialogueComponent],
})
export class ImageComponent implements OnInit {
  @Input() imageSRC:string;
  @Input() imageCROP:string;
  @Output() imageDATA:any;
  @Output() onImageSelected: EventEmitter<any> = new EventEmitter();

  data:any;
  // cropperSettings: CropperSettings;
  files:FileList;
  imageUpdated = false;

  dialogRef;

  showImageData = false;

  // @ViewChild('cropper', { static: true })
  // cropper:ImageCropperComponent;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('photoUser', { static: true }) photoUser: ElementRef;

  private isCropping = false;

  constructor(public dialog: MatDialog, private zone:NgZone) {

      this.data = {};

  }

  ngOnInit() {
    console.log("IMAGE SRC: ", this.imageSRC);
    this.data.image = this.imageSRC;
    // this.onImageSelected.emit("Initiating");
  }

  ngOnChanges(changes:SimpleChanges) {
    this.data.image = this.imageSRC;
    // this.zone.run(() => {
    // 	this.onImageSelected.emit(true);
    // })
  }

  fileChangeListener($event,type = this.imageCROP) {
      var image:any = new Image();
      this.files = $event.target.files;
      var myReader:FileReader = new FileReader();
      var that = this;
      
     // this.zone.run(() => {
    // 	this.onImageSelected.emit(true);
    // })

      myReader.onloadend = (loadEvent:any) => {
          image.src = loadEvent.target.result;
          //that.imageSRC = image.src;
          //that.openDialogWithAResult();
          let config = new MatDialogConfig;
          config.data = {
            image: image,
            event: $event,
            ctype: this.imageCROP
          }
          console.log("Opening Dialog", config);
          this.zone.run(() => {

          })
          let dialogRef = this.dialog.open(ImageDialogComponent, config);
          // console.log("Dialog Ref Opened", this.dialog.openDialogs);
          //dialogRef.componentInstance.cropper.setImage(image);

          dialogRef.afterClosed().subscribe((result) => {
        if(result){
            console.log("Image Updated, Closing dialogue");
            that.imageUpdated = true;
            that.data.image = result;
          //that.onCloseDialogue();
            that.photoUser.nativeElement.value = that.files[0].name;
            this.onImageSelected.emit(true);
          } else {
            console.log('Image Upload Cancelled');
            // this.fileInput.nativeElement.value = "";
          }
          this.isCropping = false;
          this.fileInput.nativeElement.value = "";
      });
      };
      // that.onImageSelected.next(true);
      myReader.readAsDataURL(this.files[0]);
  }

  onCloseDialogue() {
    console.log('Dialogue is closing...');
    // this.zone.run(() => {
    // 	this.onImageSelected.emit(true);
    // })
  }

  uploadClick($event) {
    console.log("Upload Button Clicked");
    this.isCropping = true;
    // this.zone.run(() => {
      // this.onImageSelected.emit(true);
    // })
    this.fileInput.nativeElement.click();
    
  }

  cropClick() {
    this.isCropping = false;
  }

  selectedOption;
}