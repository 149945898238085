export class Upload {
    $key: string;
   file:any;
   file_string:string;
   name:string;
   url:string;
   storage:string;
   progress:number;
   createdAt: Date = new Date();
   constructor(file:any) {
       this.file = file;
   }
}