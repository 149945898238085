import { Injectable } from '@angular/core';
// import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Upload } from "./upload";
// import { FirebaseApp } from 'angularfire2';
import { FirebaseApp } from '@angular/fire';
import * as firebase from 'firebase/app';
import 'firebase/storage';

import { UserService } from "./user.service";


@Injectable()
export class ImageUploaderService {
  private basePath:string = '/images';
  private uploadTask:firebase.storage.UploadTask;
  //public imageData:BehaviorSubject<Upload>;
  //public imageData:Subject<Upload>;

  // uploads:AngularFireList<Upload[]>;
  progress:BehaviorSubject<Number>;

  constructor(private firebaseApp:FirebaseApp) {
    console.log("FIREBASE: ");
    console.dir(firebaseApp);
    //this.imageData = <BehaviorSubject<any>> new BehaviorSubject([]);
    //this.imageData = <Subject<Upload>> new Subject();
  }

  pushUploadFile(upload, subPath="user"):Observable<Upload> {
  //pushUploadFile(upload, subPath="user"):firebase.storage.UploadTask {
    //debugger;
    this.progress = new BehaviorSubject<Number>(0)

    let imageData:Subject<Upload> = <Subject<Upload>> new Subject();
    let storageRef = this.firebaseApp.storage().ref();
    let metadata = {
      contentType: 'image/jpeg',
    };
    if(subPath != "user") subPath = "misc";
    this.uploadTask = storageRef.child(`${this.basePath}/${subPath}/${upload.file.name}`).put(upload.file, metadata)
    

    this.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot:any) => {
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Bytes Transferred: " + upload.progress);
      },
      (error) => {
        console.log("Firebase Storage Upload Error:", error);
      },
      () => {
        // upload success
        upload.name = upload.file.name;
        upload.ref = this.uploadTask.snapshot.ref.name;
        this.uploadTask.snapshot.ref.getDownloadURL()
                .then(downloadURL => {
                  upload.url = downloadURL;
                  imageData.next(upload);
            // this.saveFileData(upload);
            imageData.complete();
                });
      }	
    );

    // storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file)
    // 	.then(snapshot => {
    // 		console.log('Uploaded a blob or file! ' + snapshot.downloadURL);
    // 	});	

    return imageData.asObservable();
    // return this.uploadTask;
  }

  pushUploadString(upload, subPath="user", filename:string="image"+Math.random()*100, rootPath:string = this.basePath):firebase.storage.UploadTask {
    //debugger;
    this.progress = new BehaviorSubject<Number>(0)

    //let imageData:Subject<Upload> = <Subject<Upload>> new Subject();
    let storageRef = this.firebaseApp.storage().ref();
    let metadata = {
      contentType: 'image/jpeg'
    };

    //upload.file += ";name=dingus.jpg";
    //console.log("Push String Upload: ", metadata);
    // if(subPath != "user" && subPath != "merchant" && subPath != "deal" && subPath != "rewards" && subPath != "receipt") subPath = "misc";
    // this.uploadTask = storageRef.child(`${this.basePath}/${subPath}/${filename}`).putString(upload.file, firebase.storage.StringFormat.DATA_URL, metadata)
    
    try {
      console.log("Push String Upload: ", metadata, rootPath, subPath, filename);
      if(subPath != "user") subPath = "misc";
      this.uploadTask = storageRef.child(`${rootPath}/${subPath}/${filename}`).putString(upload.file, firebase.storage.StringFormat.DATA_URL, metadata)
    
    } catch (error) {
      console.log("Upload Error", error);
    }

    /*storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file)
      .then(snapshot => {
        console.log('Uploaded a blob or file! ' + snapshot.downloadURL);
      });	*/

    //return imageData.asObservable();
    return this.uploadTask;
  }

  // private saveFileData(upload: Upload) {
  // 	this.dbfs.collection(`${this.basePath}/`).add(upload);
  // }
}

/*export class Upload {
   $key: string;
  file:File;
  name:string;
  url:string;
  progress:number;
  createdAt: Date = new Date();
  constructor(file:File) {
    this.file = file;
  }
}*/