import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HelpDialogComponent } from '../edit/help-dialog/help-dialog.component';
import { AuthService } from '../services/auth.service';
import { ImageUploaderService } from '../services/image-uploader.service';
import { UserService } from '../services/user.service';

import * as firebase from 'firebase';
import * as _ from 'lodash';
import { ImageComponent } from '../image/image.component';
import { time } from 'console';


@Component({
  selector: 'app-rest-edit',
  templateUrl: './rest-edit.component.html',
  styleUrls: ['./rest-edit.component.scss']
})
export class RestEditComponent implements OnInit {

  public validationFormInfo:FormGroup;

  private userID:string = '';
  private userSub:Subscription;
  private defaultData:any = {
    restaurant_name:'',
    phone:'',
    email:'',
    location:'',
    location2:'',
    city:'',
    website:'',
    restaurant_settings: {
      enable_phone:false,
    },
  }
  public userData:any = this.defaultData

  public formSubmittedInfo:boolean = false;
  public serverErrorInfo:boolean = false;
  public errorMsgInfo:string = '';

  public menus;

  square = 'square'
  cover = 'cover'


  @ViewChild('logoImage', { static: true }) userImage: ImageComponent;
  public imageSRC = '/assets/broken_image_24px.svg';
  @ViewChild('coverImage', { static: true }) coverImage: ImageComponent;
  public coverSRC = '/assets/broken_image_24px.svg';

  @ViewChild("search", { static: true }) searchElementRef: ElementRef;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private authService: AuthService,
    private db: AngularFirestore,
    private userService: UserService,
    public dialog: MatDialog,
    private imageUploader:ImageUploaderService

  ) { }

  ngOnInit(): void {
    this.buildFormInfo();
    this.userService.userData.subscribe(result => {
      console.log("USERDATA FOUND:",result,typeof result)
      // this.userData = Object.assign(this.defaultData,result)
      // this.userData = {...this.defaultData,...result}
      this.userData = _.merge(this.userData, result);
      this.userID = result.id;
      console.log('USERDATA after Object.assign:',this.userData)
     
      this.populateFormInfo()
    });

    this.menus = this.userService.getMenus();

  }

  ngOnDestroy():void {
    if(this.userSub) this.userSub.unsubscribe();
  }

  buildFormInfo() {
    this.validationFormInfo = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required]),
      phone: this.formBuilder.control(null, [Validators.minLength(10),Validators.pattern("^[0-9]*$")]),
      email: this.formBuilder.control(null, [Validators.email]),
      location: this.formBuilder.control(null, []),
      location2: this.formBuilder.control(null, []),
      logo: this.formBuilder.control(null, []),
      background: this.formBuilder.control(null, []),
      website: this.formBuilder.control(null, []),
      phoneToggle: this.formBuilder.control(null, []),
    });
  }

  populateFormInfo(){
    this.validationFormInfo.get('name').setValue(this.userData.restaurant_name);
    this.validationFormInfo.get('location').setValue(this.userData.location);
    this.validationFormInfo.get('location2').setValue(this.userData.location2);
    this.validationFormInfo.get('phoneToggle').setValue(this.userData.restaurant_settings.enable_phone);
  }

  resetMessaging() {
    this.serverErrorInfo = false;
    this.errorMsgInfo = '';
  }

  addMenu(){
    let order = this.userData.menus ? this.userData.menus : 0;
    this.userService.addMenu(order)
    .then(result => {
      console.log('add menu successful');
    }).catch(error => {
      console.log('error adding menu', error);
    })
  }

  editMenu(menu) {
    console.log(`navigate here r/edit/${menu.id}`)
    this.router.navigate([`r/edit/${menu.id}`],{});
  }

  deleteMenu(menu){
    this.userService.rmMenu(menu)
    .then(result => {
      console.log('delete menu successful');
    }).catch(error => {
      console.log('error adding menu', error);
    })
  }

  menuToggle(menu) {
    menu.visible = !menu.visible;
    this.userService.updateMenu(menu)
    .then(result => {
      console.log('update menu successful');
    }).catch(error => {
      console.log('error updating menu', error);
    })
  }

  onInfoSubmit() {
    console.log('info SAVE clicked');
    this.resetMessaging();

    let newData = {
      restaurant_name:this.validationFormInfo.get('name').value,
      phone:this.validationFormInfo.get('phone').value,
      email:this.validationFormInfo.get('email').value,
      location:this.validationFormInfo.get('location').value,
      location2:this.validationFormInfo.get('location2').value,
      city:this.userData.city,
      website:this.validationFormInfo.get('website').value,
    }
    this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorInfo = true;
      this.errorMsgInfo = "Information Saved";
      this.populateFormInfo();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorInfo = true;
      this.errorMsgInfo = "Error: information not saved."  
    })
    // this.uploadPhoto();
  }

  viewMenu() {
    console.log('view menu clicked');
  }

  getLink() {
    console.log('getLink clicked!');
    this.copyToClipboard(`${window.location.origin.toString()}/menu/${this.userID}`);
  }

  signOut() {
    this.authService.logout().then(result => {
      this.router.navigate(['login'])
    })
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '250px',
      data: {type:data}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
    });
  }

  autocompleteFocus() { 
    console.log('load places api autocomplete')
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          console.log('PLACE: ');
          console.dir(place);
      
          let address = place.address_components;

          let addressStr = address[0].long_name + " " + address[1].long_name + ",\n " + address[2].long_name + ", " + address[5].short_name + " " + address[7].long_name;
          
          // let parseObj = this.parseGoogleResponse(place.address_components);
          this.validationFormInfo.get('location').setValue(addressStr);
          this.userData.city = address[2].long_name + ", " + address[5].short_name;
          // this.validationForm.get('merchantAddress1').setValue(parseObj.address1);
          // this.validationForm.get('addressSearch').setValue(addObj.address1);
          // this.validationForm.get('merchantCity').setValue(parseObj.city);
          // this.selectState.selectedValue = parseObj.state;
          // this.validationForm.get('merchantZip').setValue(parseObj.zip);

        });
      });
    });
  }

  parseGoogleResponse(components:any):any {
    let addObj:any = {};
    addObj.address1 = "";
    _.each(components, function(component:any) {
      _.each(component.types, function(type) {
        if (type === 'street_number') {
          addObj.address1 = component.long_name;
        }
        if (type === 'route') {
          if(addObj.address1 != "") {
            addObj.address1 += " " + component.long_name;
          } else {
            addObj.address1 = component.long_name;
          }
        }
        if (type === 'locality') {
          addObj.city = component.long_name;
        }
        if (type === 'administrative_area_level_1') {
          addObj.state = component.short_name;
        }
        if (type === 'postal_code') {
          addObj.zip = component.long_name;
        }
      })
    })
    console.log("PARSER RESPONSE");
    // console.dir(typeof addObj);
    return addObj.address1 + ", " + addObj.city + "" + addObj.state + addObj.zip;
  }

  copyToClipboard(textToCopy) {
    var textArea;

    console.log('link check:',textToCopy)
  
    function isOS() {
      //can use a better detection logic here
      return navigator.userAgent.match(/ipad|iphone/i);
    }
  
    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }
  
    function selectText() {
      var range, selection;
  
      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }
  
    function copyTo() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  
    createTextArea(textToCopy);
    selectText();
    copyTo();
  }

}
