import { Injectable } from '@angular/core';
import { first, catchError,  take, defaultIfEmpty ,  map, switchMap, mergeMap, combineLatest, concatMap, toArray, mergeAll, concatAll, zip, retry } from 'rxjs/operators';


import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { InvitecodeService } from './invitecode.service';
import { AuthService } from './auth.service';
import * as firebase from 'firebase';
import { promise } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userData:Observable<any>;

  constructor(
    private db: AngularFirestore,
    private inviteCodeService:InvitecodeService,
    private authService:AuthService,
  ) { 
    this.userData = authService.firebaseAuth.authState.pipe(switchMap(authUser => {
      if(authUser){
        return db.doc(`/users/${authUser.uid}`)
          .snapshotChanges().pipe(
                map((changes:any) => {
                  console.log('userdata',changes.payload.data() )
                  return { id: changes.payload.id, key: changes.payload.id, ...changes.payload.data() };
              })
            );
      } else {
        return of();
      }
    }));
   }

  createNewUserRef(uid:string,email:string,name:string):Promise<any> {
    let timestamp = Date.now();
    let nameArr = name.split(" ");
    return this.db.doc("/users/" + uid).set({
      "email": email,
      "first_name": nameArr[0],
      "last_name": nameArr.length > 1 ? (nameArr.slice(1)).join(' ') : '',
      "admin":false,
      "timestamp_created": timestamp,
      "code": this.inviteCodeService.generateCode(),
      "custom_id_enabled":false,
      "alte_premium":false,
    })
  }

  createNewRestaurantRef (uid:string,email:string,name:string,rName:string = ''):Promise<any> {
    let timestamp = Date.now();
    let nameArr = name.split(" ");
    return this.db.doc("/users/" + uid).set({
      "email": email,
      "first_name": nameArr[0],
      "last_name": nameArr.length > 1 ? (nameArr.slice(1)).join(' ') : '',
      "admin":false,
      "timestamp_created": timestamp,
      "code": this.inviteCodeService.generateCode(),
      "custom_id_enabled":false,
      "alte_premium":false,
      "restaurant_admin":true,
      "restaurant_name":rName
      
    })
  }

  checkRestaurantUser() {
    console.log("checkRestaurant",this.authService.userID);
    return this.db.doc(`/users/${this.authService.userID}`).valueChanges().pipe(
      switchMap((user:any) => {
        if (user.restaurant_admin) {
          return of(true)
        } else {
          return of(false);
        }
      })
    );
  }

  getUserInfo():Observable<any> {
    console.log("Get Auth User Info",this.authService.userID);
    return this.db.doc(`/users/${this.authService.userID}`).valueChanges();
  }

  getUserInfoByID(uid:string):Observable<any> {
    console.log("Getting User by UserID", uid);
    return this.db.doc(`/users/${uid}`).valueChanges().pipe(take(1));
  }

  getUserInfoByCustID(id:string):Observable<any> {
    console.log("Getting User by CustID", id);
    // return this.db.collection('users', ref => ref.where('custom_id', '==', id).where('custom_id_enabled','==',true).where('alte_premium','==',true)).valueChanges().pipe(
    return this.db.collection('users', ref => ref.where('custom_id', '==', id).where('custom_id_enabled','==',true)).valueChanges().pipe(
        first(),
			map((user:any) => {
        console.log(user);
				if(user[0]) {
					return user[0];
				} else {
          console.log('no custom id found, try db id')
          // return this.db.doc(`/users/${id}`).valueChanges().pipe(first());
          return null;
				}
			})
    )  
  }
  
  saveUserInfo(userData):Promise<any> {
    console.log("Saving User Info",this.authService.userID,userData);
    return this.db.doc(`/users/${this.authService.userID}`).update(userData);
  }

  saveUserToggle(field,value):Promise<any> {
    console.log('Saving User Toggle',field,value);
    // return this.db.doc(`/users/${this.authService.userID}/vc_settings/enable_${field}`).update(
    //   value
    // )
    return this.db.doc(`/users/${this.authService.userID}`).update({
      [`vc_settings.enable_${field}`]:value
    });
  }

  addLink(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      social_links: firebase.firestore.FieldValue.arrayUnion(linkObj)
    });
  }

  rmLink(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      social_links: firebase.firestore.FieldValue.arrayRemove(linkObj)
    });
  }

  addPayme(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      payme_links: firebase.firestore.FieldValue.arrayUnion(linkObj)
    });
  }

  rmPayme(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      payme_links: firebase.firestore.FieldValue.arrayRemove(linkObj)
    });
  }

  addCustom(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      custom_links: firebase.firestore.FieldValue.arrayUnion(linkObj)
    });
  }

  rmCustom(linkObj):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      custom_links: firebase.firestore.FieldValue.arrayRemove(linkObj)
    });
  }

  updateUserImage(userID:string,url:string):Promise<any> {
    return this.db.collection('users').doc(userID)
    .update({
        photo_url: url
      })
      .catch(error => {
        console.log("Error Updating User Image");
      })
  }
  updateCoverImage(userID:string,url:string):Promise<any> {
    return this.db.collection('users').doc(userID)
    .update({
        cover_url: url
      })
      .catch(error => {
        console.log("Error Updating User Image");
      })
  }

/** 
  * Begin Restaraunt Menu Specific User Service
  */

  getMenus() {
    console.log("getmenu",this.authService.userID)
    return this.authService.currentUser.pipe(take(1),switchMap((user:any) => {
			if(user) {
				return this.db.collection("users").doc(user.uid).collection('menus').valueChanges()
			} else {
				return of(false);
			}
		}),);
  }

  addMenuArr(menu):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      menus: firebase.firestore.FieldValue.arrayUnion(menu)
    });
  }

  rmMenuArr(menu):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).update({
      menus: firebase.firestore.FieldValue.arrayRemove(menu)
    });
  }

  addMenu(order):Promise<any> {
    const db = firebase.firestore();
    let timestamp = Date.now();
    let menuRef = db.collection(`/users/${this.authService.userID}/menus`).doc();
    return menuRef.set({
      title:'New Menu',
      description:"",
      order:order,
      timestamp_created:timestamp,
      visible:true,
      user_id:this.authService.userID,
      id: menuRef.id
    });
  }

  rmMenu(menu):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).collection('menus').doc(menu.id).delete();
  }

  updateMenu(menu):Promise<any> {
    console.log('updateMenu',menu)
    return this.db.doc(`/users/${this.authService.userID}`).collection('menus').doc(menu.id).update({
      menu
    });
  }

  getSubMenus(menuID) {
    console.log("getsubmenu",this.authService.userID)
    return this.authService.currentUser.pipe(take(1),switchMap((user:any) => {
			if(user) {
				return this.db.collection("users").doc(user.uid).collection('menus').doc(menuID).collection('submenus').valueChanges()
			} else {
				return of(false);
			}
		}),);
  }

  addSubMenu(order,menuID):Promise<any> {
    const db = firebase.firestore();
    let timestamp = Date.now();
    let submenuRef = db.collection(`/users/${this.authService.userID}/menus/${menuID}/submenus`).doc();
    return submenuRef.set({
      title:'New Sub Menu',
      description:"",
      order:order,
      timestamp_created:timestamp,
      visible:true,
      user_id:this.authService.userID,
      id: submenuRef.id
    });
  }

  rmSubMenu(menuID,submenu):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).collection('menus').doc(menuID).collection('submenus').doc(submenu.id).delete();
  }

  updateSubMenu(menuID,submenu):Promise<any> {
    return this.db.doc(`/users/${this.authService.userID}`).collection('menus').doc(menuID).update({
      submenu
    });
  }

}
