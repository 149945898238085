// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyByPGLtq8iv6JdzRwRErZpNNGLrOjYddI4",
    authDomain: "tappid-8be19.firebaseapp.com",
    databaseURL: "https://tappid-8be19.firebaseio.com",
    projectId: "tappid-8be19",
    storageBucket: "tappid-8be19.appspot.com",
    messagingSenderId: "187007832218",
    appId: "1:187007832218:web:7198f2c786c46fd4c48f23",
    measurementId: "G-LJ03F0TDX1"
  },
  stripeAPIlive:"pk_live_51HzI77G6QgB3Zo94C83S9nmsjWiILowQObJdrOFqxkyyYR0b1JBJlzpNFmNXamSFlUsWyW2ztA5vI1INqahUNeRX00e6aKxgMJ",
  stripeAPItest:"pk_test_51HzI77G6QgB3Zo94BqLJCUAgvin2MhQfud2prciEET6HNuAIY0gHANtIrLnGM5j8BQSU3WhxcFV2HeS9kLM9WhhO00CA2W8P7D",
  tinyMCEapi:"g8akvaaryv45k15yv71p2m2srn516un9ybcqq3t9p17vybot"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
