import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss','../edit.component.scss']
})
export class UserThemeComponent implements OnInit {

  public userID:string = '';
  public userData:any;
  private userSub:Subscription;

  public validationFormTheme:FormGroup;

  constructor(
    private userService: UserService,
    private authService:AuthService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.buildFormTheme();
    this.userService.userData.subscribe(result => {
      console.log("USERDATA FOUND:",result,typeof result)
      // this.userData = Object.assign(this.defaultData,result)
      // this.userData = {...this.defaultData,...result}
      this. userData = _.merge(this.userData, result);
      this.userID = result.id;
      this.populateFormTheme()
    });
  }

  ngOnDestroy():void {
    if(this.userSub) this.userSub.unsubscribe();
  }

  buildFormTheme() {
    this.validationFormTheme = this.formBuilder.group({
      custom_id: this.formBuilder.control(null, []),
      custom_id_Toggle: this.formBuilder.control(null, []),
    });
  }

  populateFormTheme() {
    this.validationFormTheme.get('custom_id').setValue(this.userData.custom_id);
    this.validationFormTheme.get('custom_id_Toggle').setValue(this.userData.custom_id_enabled);
  }

  onChangedTheme(field) {
    console.log('onChangedTheme',field);
    // this.serverError = false;
    // this.errorMsg = "";
    let value:any = this.validationFormTheme.get(field).value 
    return this.userService.saveUserInfo({[field]:value,[`${field}_enabled`]:true}).then(() => {
      // this.serverError = true;
      // this.errorMsg = "Information Saved";
      this.populateFormTheme();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      // this.serverError = true;
      // this.errorMsg = "Error: information not saved."  
    })
  }

  themeToggle(field) {
    console.log('idToggle',field);
    // this.serverError = false;
    // this.errorMsg = "";
    let value = this.validationFormTheme.get(`${field}_Toggle`).value;
    return this.userService.saveUserInfo({[`${field}_enabled`]:!value}).then(() => {
      // this.serverError = true;
      // this.errorMsg = "Information Saved";
      this.populateFormTheme();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      // this.serverError = true;
      // this.errorMsg = "Error: information not saved."  
    })
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '250px',
      data: {type:data}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
    });
  }

  viewProfile() {
    if (this.userData.alte_premium && this.userData.custom_id_enabled && this.userData.custom_id != '') {
      this.router.navigate([`me/${this.userData.custom_id}`], { });
    } else {
      this.router.navigate([`me/${this.userID}`], { });
    }  
  }

  goBack() {
    console.log('goBack clicked!');
    this.router.navigate([`me/edit`], { });
  }

  getLink() {
    console.log('getLink clicked!');
    // this.copyToClipboard(`${window.location.origin.toString()}/me/${this.userID}`);
  }

  goEdit() {
    console.log('goEdit clicked!');
    this.router.navigate([`me/edit`], { });
  }

  goPay() {
    console.log('goPay clicked!');
    this.router.navigate([`me/edit/pay`], { });
  }

  goTheme() {
    console.log('goTheme clicked!');
    this.router.navigate([`me/edit/theme`], { });
  }

  viewSettings() {
    console.log('')
  }

  signOut() {
    this.authService.logout().then(result => {
      this.router.navigate(['login'])
    })
  }

  resetMessaging() {
    // this.serverErrorIntro = false;
    // this.errorMsgIntro = '';
  }
}
