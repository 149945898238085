import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditComponent } from './edit/edit.component';
import { UserPaymentComponent } from './edit/payment/payment.component';
import { UserThemeComponent } from './edit/theme/theme.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { PrivacyComponent } from './official/privacy/privacy.component';
import { TermsComponent } from './official/terms/terms.component';
import { MenuEditComponent } from './rest-edit/menu-edit/menu-edit.component';
import { RestEditComponent } from './rest-edit/rest-edit.component';
import { RestLayoutComponent } from './rest-layout/rest-layout.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: ':id', component: LayoutComponent },

  { path: 'me/edit', component: EditComponent },
  { path: 'me/edit/theme', component: UserThemeComponent },
  { path: 'me/edit/pay', component: UserPaymentComponent },
  { path: 'me/:id', component: LayoutComponent },
  { path: 'u/:id', component: LayoutComponent },

  { path: 'menu/edit', component: RestEditComponent },
  { path: 'menu/edit/:id', component: MenuEditComponent },
  { path: 'menu/:id', component: RestLayoutComponent },
  { path: 'r/edit', component: RestEditComponent },
  { path: 'r/edit/:id', component: MenuEditComponent },
  { path: 'r/:id', component: RestLayoutComponent },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
