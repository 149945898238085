import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-user-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class UserPaymentComponent implements OnInit {

  public userID:string = '';
  public userData:any;

  constructor(
    private userService: UserService,
    private authService:AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userService.userData.subscribe(result => {
      console.log("USERDATA FOUND:",result,typeof result)
      // this.userData = Object.assign(this.defaultData,result)
      // this.userData = {...this.defaultData,...result}
      this. userData = _.merge(this.userData, result);
      this.userID = result.id;
    });
  }

  viewProfile() {
    this.router.navigate([`me/${this.userID}`], { });
  }

  goBack() {
    console.log('goBack clicked!');
    this.router.navigate([`me/edit`], { });
  }

  goEdit() {
    console.log('goEdit clicked!');
    this.router.navigate([`me/edit`], { });
  }

  getLink() {
    console.log('getLink clicked!');
    // this.copyToClipboard(`${window.location.origin.toString()}/me/${this.userID}`);
  }

  goTheme() {
    console.log('goTheme clicked!');
    this.router.navigate([`me/edit/theme`], { });
  }

  viewSettings() {
    console.log('')
  }

  signOut() {
    this.authService.logout().then(result => {
      this.router.navigate(['login'])
    })
  }

}
