<mat-toolbar>
    <!-- <span>Edit My Information</span> -->
    <span class="example-spacer"></span>
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" *ngIf="isLoggedIn()"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editProfile()"><mat-icon>preview</mat-icon><span>Edit Profile</span></button>
        <button mat-menu-item (click)="goTheme()"><mat-icon>link</mat-icon><span>Edit Theme</span></button>
        <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
        <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
    </mat-menu>
</mat-toolbar>
<div class="layout-page">
    <div class="image-container" [ngClass]="{'no-cover' : data.cover_url == '' || !data.vc_settings.enable_cover}">
        <div class="cover-frame">
            <!-- <img *ngIf="data.photo_url != ''" [src]="data.photo_url"/> -->
            <img *ngIf="data.cover_url != '' && data.vc_settings.enable_cover" [src]="data.cover_url"/>
        </div>
        <div class="prof-frame" *ngIf="data.photo_url != ''">
            <img [src]="data.photo_url">
        </div>
    </div>
    <div class="row heading" [ngClass]="{'no-cover' : data.cover_url == '' || !data.vc_settings.enable_cover}">
        <div class="" [ngClass]="{'payments-flex' : data.payments_top}">
            <div class="intro">
                <h2 class="name">
                    {{data.first_name}} {{data.last_name}}
                </h2>
                <p *ngIf="data.title != '' || data.company != '' || data.location != ''">
                    {{data.title}} {{data.title != '' && data.company != '' ? '@' : ''}} {{data.company != '' ? data.company : ''}}
                    <br *ngIf="data.title != '' || data.company != '' && data.city != ''">
                    {{ data.city }}
                </p>
                <p *ngIf="data.website">
                    <a href="{{linkFormatter(data.website)}}">{{data.website.replace('https://','').replace('http://','')}}</a>
                </p>
            </div>
            <div class="payme-top-right" *ngIf="data.payments_top && showPayments">
                <div class="link-holder" *ngFor="let payme of data.payme_links">
                    <div *ngIf='payme.visible'>
                        <a href="{{getLink(payme)}}">
                            <div class="link-icon">
                                <div class="payme-text" *ngIf="payme.type == 'cashapp'">Ca$hApp</div>
                                <div class="payme-text" *ngIf="payme.type == 'venmo'">Venmo</div>
                                <div class="payme-text" *ngIf="payme.type == 'paypal'">PayPal</div>
                                <div class="payme-text" *ngIf="payme.type == 'bitcoin'">Bitcoin</div>
                                <div class="payme-text" *ngIf="payme.type == 'dogecoin'">Dogecoin</div>
                                <div class="payme-text" *ngIf="payme.type == 'ethereum'">Ethereum</div>
                                <!-- <img *ngIf="payme.type == 'cashapp'" src="/assets/icons/Square_Cash_app_logo.svg" style="height: 22px;">
                                <img *ngIf="payme.type == 'venmo'" src="/assets/icons/venmo_logo_blue.png" style="height: auto; margin: 6px 4px; max-width: 44px;">
                                <img *ngIf="payme.type == 'paypal'" src="/assets/icons/paypal_seeklogo.com.svg" style="height: 22px;"> -->
                            </div>
                            <!-- <div class="link-text">
                                <span>{{payme.link}}</span>
                            </div> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bio">
            <p class="" [innerHTML]='data.bio'></p>
        </div>
    </div>

    <div class="row payme" *ngIf="showPayments && !data.payments_top">
        <!-- <div class="title">
            <h2><mat-icon>payment</mat-icon>&nbsp;&nbsp;Payments:</h2>
        </div> -->
        <div class="payme-fixed">
            <div class="payme-holder">
                <div class="link-holder" *ngFor="let payme of data.payme_links">
                    <div *ngIf='payme.visible'>
                        <a>
                            <div class="link-icon">
                                <a href="{{getLink(payme)}}"><img *ngIf="payme.type == 'cashapp'" src="/assets/icons/Square_Cash_app_logo.svg"></a>
                                <a href="{{getLink(payme)}}"><img *ngIf="payme.type == 'venmo'" src="/assets/icons/venmo_logo_blue.png" style="height: auto; margin: 8px 4px;"></a>
                                <a href="{{getLink(payme)}}"><img *ngIf="payme.type == 'paypal'" src="/assets/icons/paypal_seeklogo.com.svg"></a>
                                <img (click)="copyWalletAddress(payme.link)" *ngIf="payme.type == 'bitcoin'" src="/assets/icons/bitcoin.svg">
                                <img (click)="copyWalletAddress(payme.link)" *ngIf="payme.type == 'dogecoin'" src="/assets/icons/dogecoin.svg">
                                <img (click)="copyWalletAddress(payme.link)" *ngIf="payme.type == 'ethereum'" src="/assets/icons/ethereum_icon.png">
                            </div>
                            <!-- <div class="link-text">
                                <span>{{payme.link}}</span>
                            </div> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-divider *ngIf="data.social_links.length > 0 && showPayments"></mat-divider>

    <div class="row socials" *ngIf="data.social_links.length > 0">
        <!-- <div class="title">
            <h2><mat-icon>people</mat-icon>&nbsp;&nbsp;Connect:</h2>
        </div> -->
        <div class="socials-fixed">
            <div class="socials-holder" [ngClass]="{'inline' : data.show_socials_text}">
                <div class="link-holder" *ngFor="let social of data.social_links">
                    <div class="social-link" *ngIf='social.visible'>
                        <a href="{{socialFormat(social)}}">
                            <div class="link-icon">
                                <i class="fab fa-{{(social.icon)}}"></i>
                            </div>
                            <div class="link-text" *ngIf="data.show_socials_text">
                                <span>{{social.link}}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <mat-divider *ngIf="data.social_links.length > 0 && showPayments"></mat-divider> -->

    <!-- <mat-divider *ngIf="showPayments"></mat-divider> -->

    <!-- <mat-expansion-panel *ngIf="isAdminUser()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Dev Info
          </mat-panel-title>
          <mat-panel-description>
            
          </mat-panel-description>
        </mat-expansion-panel-header>
            <p>VCard String Preview:</p>
            <p>{{vCardString}}</p>      
    </mat-expansion-panel> -->

    <div class="row footer">
        <p><b>Alte</b></p>
        <p><mat-icon>code</mat-icon>: &nbsp; <a href="https://github.com/anthonyescobar">Anthony Escobar</a></p>
        <p><a href="/login">Create Your Alte Here</a></p>
        <!-- <p style="width: 190px; margin:auto; font-size: 10px;">All data used for this application is encrypted off site by <a href="https://cloud.google.com/firestore/docs/server-side-encryption">google</a>.</p> -->
    </div>
</div>

<div class="fixed-bottom">
    <button mat-fab color="primary" [vcdDownloadVCard]="vCard" [encoding]="vCardEncoding.none"><mat-icon>person_add</mat-icon></button>
</div>

<!-- <div>
        <button id="downloadButton" [vcdDownloadVCard]="vCard" [encoding]="vCardEncoding.none">Download VCard!</button>
</div> -->