<p>image-dialog works!</p>
<div class="image-dialog">
    <div mat-dialog-content>Crop your image to fit the thumbnail.</div>
    <div mat-dialog-actions>
          <!-- <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper> -->
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="ratio"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
    </div>
    <div class="text-center" style="margin-top: 20px;">
              <!-- <button mat-button (click)="dialogRef.close(data.image)">Select Image</button> -->
        <button (click)="dialogRef.close(null)" style="margin-right: 15px;" class="mat-raised-button " color="" mat-raised-button="" ng-reflect-color="primary">
            <span class="mat-button-wrapper">CANCEL</span>
            <div class="mat-button-ripple mat-ripple" mat-ripple="" ng-reflect-trigger="[object HTMLButtonElement]" ng-reflect-centered="false" ng-reflect-disabled="false">				
            </div> 
            <div class="mat-button-focus-overlay"></div>
        </button>
        <button (click)="dialogRef.close(croppedImage)" class="mat-raised-button " color="" mat-raised-button="" ng-reflect-color="primary">
            <span class="mat-button-wrapper">SELECT IMAGE</span>
            <div class="mat-button-ripple mat-ripple" mat-ripple="" ng-reflect-trigger="[object HTMLButtonElement]" ng-reflect-centered="false" ng-reflect-disabled="false">				
            </div> 
            <div class="mat-button-focus-overlay"></div>
        </button>
          </div>
    <div class="image-container upload">
        <div class="user-image">
            <img alt="" style="width:100%" [src]="croppedImage" > 
            <!-- <img alt="avatar" class="avatar" [src]="imageSRC" >  -->
        </div>		
    </div>
</div>