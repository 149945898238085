<div class="edit-page menu-edit">
    <mat-toolbar>
        <span><mat-icon (click)="goBack()">arrow_back_ios</mat-icon>&nbsp;Edit Menu</span>
        <span class="example-spacer"></span>
        <button mat-mini-fab color="primary"  [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item (click)="viewMenu()"><mat-icon>preview</mat-icon><span>Preview Menu</span></button> -->
            <!-- <button mat-menu-item (click)="getLink()"><mat-icon>link</mat-icon><span>Get Menu Link</span></button> -->
            <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
            <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
        </mat-menu>
    </mat-toolbar>
    <div class="toolbar-spacing"></div>
    <div class="row" cdkDropListGroup>
        <mat-accordion *ngIf="submenusdb" cdkDropList>
            <form [formGroup]="menuForm">
                <div formArrayName="submenusForm">
                    <!-- <mat-expansion-panel *ngFor="let menu of submenusdb | async; let i=index" cdkDrag> -->
                    <mat-expansion-panel *ngFor="let menu of submenusdb; let i=index" cdkDrag>
                        <mat-expansion-panel-header style="min-height: 100px;">
                            <mat-panel-title>
                                <mat-form-field appearance="fill" class="full-width">
                                    <mat-label>Section Name</mat-label>
                                    <input matInput formControlName="{{i}}" placeholder="Appetizers" type="text"
                                    [(ngModel)]="menu.title" (change)="onSubMenuTitleChange(i,menu)">
                                </mat-form-field>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!-- <mat-form-field appearance="fill" class="full-width">
                                    <mat-label>Description (optional)</mat-label>
                                    <textarea matInput formControlName="sub-desc-{{i}}" placeholder="" type="text"></textarea>
                                </mat-form-field> -->
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
 
                            <div>
                                
                                
                            </div>
                                
                        </div>
                        <div class="full-width">
                            Items
                        </div>
                        <button mat-icon-button color="primary" class="add-btn" (click)="addSubMenuItem(menu.id)">Add Item<mat-icon>add</mat-icon></button>           
                    </mat-expansion-panel>
                </div>                 
            </form>
        </mat-accordion>
        <button mat-icon-button color="primary" class="add-btn" (click)="addSubMenu()">Add Menu<mat-icon>add</mat-icon></button>
    </div>
</div>