import { Component, Inject, OnInit, ViewChild, Input, Output, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// openDialogWithAResult
@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.component.html',
  // template: `<h1>Image Cropper</h1>`
})
export class ImageDialogComponent {

  data:any;
  // cropperSettings: CropperSettings;
  // files:FileList;
  //config:any;

  imageChangedEvent: any = '';
  croppedImage: any = '';


  ratio: number = 3.75 / 2;

  // @ViewChild('cropper')
  // cropper:ImageCropperComponent;
    
    constructor(public dialogRef: MatDialogRef<ImageDialogComponent>, 
      @Inject(MAT_DIALOG_DATA) private config: any) {

  // 		this.cropperSettings = new CropperSettings();
   //    this.cropperSettings.noFileInput = true;
   //    //this.cropperSettings.cropperClass = "";
   // 	this.cropperSettings.width = 3.75;
    // this.cropperSettings.height = 2.00;
    // this.cropperSettings.croppedWidth = 469;
    // this.cropperSettings.croppedHeight = 250;
    // this.cropperSettings.canvasWidth = 285;
    // this.cropperSettings.canvasHeight = 225;
  //   this.cropperSettings.compressRatio = 0.8;
    //this.cropperSettings.dynamicSizing = true;

      this.data = {};
    
      console.log("Config Data: ", config);

      this.imageChangedEvent = config.event;
      if(config.ctype == 'square') {
        this.ratio = 1/1
      } else if (config.ctype == 'cover') {
        this.ratio = 3.75/2
      } else {
        this.ratio = 3.75/2
      }
      
    }


    // getImageCrop() {
    //   console.log('HERE',this.ratio);
    //   return this.ratio;
    // }

    fileChangeEvent(event: any): void {
        console.log("File Change event", event);
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        console.log("Image cropped", event);
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
        console.log("Image loaded", this.croppedImage);
    }
    cropperReady() {
        // cropper ready
        console.log("Cropper Ready", this.croppedImage);
    }
    loadImageFailed() {
        // show message
        console.log("Load image failed", this.croppedImage);
    }

    ngOnInit() {
      //debugger;
      // console.log("Image Dialog INIT", this.cropperSettings, this.cropper);
      /*this.cropperSettings = new CropperSettings();
      this.cropperSettings.noFileInput = true;
      this.data = {};*/

      // let image:any = new Image();
      // image.src = this.config.image.src;
      // console.log("Setting cropper img", image.src);
      // this.cropper.settings = this.cropperSettings;
      // this.cropper.setImage(image);
    }

    initCropper(image:any) {
      //debugger;
      console.log("Init Cropper Image", image);
      // this.cropper.setImage(image);

    }
}

