import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {

  public header = '';
  public text = '';

  constructor(public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data.type == "vcard") {
      this.header = 'vCard Help';
      this.text = 'Use the toggles to enable or disable that contact information from appeading on your digital contact card. With this feature, you can have all your information showing on the webpage but only allow certain information to be shared through the "add to contacts" button.';
    } else if (this.data.type == "profile") {
      this.header = 'Profile Help';
      this.text = 'Add extra information and flair to your profile here. Data in this section are not included in your digital contact card.';
    } else if (this.data.type == "links") {
      this.header = 'Links Help';
      this.text = 'Use the eyeball to hide or show certain links on your page. The trash can will delete that link completely. You may add links from the "Add +" below the form.';
    } else if (this.data.type == "payme") {
      this.header = 'PayMe Help';
      this.text = 'Use the eyeball to hide or show certain payment accounts on your page. The trash can will delete that link completely. You may add paymes from the "Add +" below the form.';
    } else if (this.data.type == "menumain") {
      this.header = 'Menu Help';
      this.text = '.';
    } else if (this.data.type == "menuitem") {
      this.header = 'Menu Help';
      this.text = 'Use the eyeball to hide or show certain items on your menu. The trash can will delete that item completely. You may add items from the "Add +" below the form.';
    } else {
      this.header = 'whoops';
      this.text = 'This help button is disconnected or not set up yet!';

    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
