import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageComponent } from './image.component';
import { ImageDialogComponent } from './image-dialog.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    ImageComponent,
    ImageDialogComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule
  ],
  exports: [
    ImageComponent
  ]
})
export class ImageModule { }
