import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'protractor';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { MapsAPILoader } from '@agm/core';

import * as firebase from 'firebase';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { ImageComponent } from '../image/image.component';
import { Upload } from '../services/upload';
import { ImageUploaderService } from '../services/image-uploader.service';

import { environment } from '../../environments/environment'


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public tinyMCEkey = environment.tinyMCEapi;

  public validationFormIntro:FormGroup;
  public validationFormLinks:FormGroup;
  public validationFormPayme:FormGroup;
  public validationFormCustom:FormGroup;

  public formSubmittedIntro:boolean = false;
  public serverErrorIntro:boolean = false;
  public errorMsgIntro:string = '';

  public formSubmittedLinks:boolean = false;
  public serverErrorLinks:boolean = false;
  public errorMsgLinks:string = '';

  public formSubmittedPayme:boolean = false;
  public serverErrorPayme:boolean = false;
  public errorMsgPayme:string = '';

  public formSubmittedCustom:boolean = false;
  public serverErrorCustom:boolean = false;
  public errorMsgCustom:string = '';

  private userID:string = '';

  private userSub:Subscription;

  private defaultData:any = {
    alte_premium:false,
    first_name:'',
    last_name:'',
    phone:'',
    email:'',
    title:'',
    company:'',
    location:'',
    location2:'',
    city:'',
    bio:'',
    website:'',
    vc_settings: {
      enable_phone:false,
      enable_email:false,
      enable_company:false,
      enable_title:false,
      enable_location:false,
      enable_image:false,
      enable_cover:false,
      enable_url:false,

    },
    social_links: [
      {type:'', order:0, link:'', icon:'', visible:false}
    ],
    payme_links: [
      {type:'', order:0, link:'', icon:'', visible:false}
    ],
    custom_links: [
      {type:'', order:0, link:'', title:'', visible:false}
    ],
    show_socials_text:true,
    payments_top:false,
    photo_url:'',
    cover_url:''
  }

  public imageUpload;
  
  private cityHolder = '';
  public userData:any = this.defaultData

  hide = true

  public showLinks:boolean = true;
  public showPayme:boolean = true;
  public showText:boolean = false;
  public showCustom:boolean = false;

  @ViewChild('userImage', { static: true }) userImage: ImageComponent;
  public imageSRC = '/assets/broken_image_24px.svg';
  @ViewChild('coverImage', { static: true }) coverImage: ImageComponent;
  public coverSRC = '/assets/broken_image_24px.svg';

  square = 'square'
  cover = 'cover'

  @ViewChild("search", { static: true }) searchElementRef: ElementRef;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private authService: AuthService,
    private db: AngularFirestore,
    private userService: UserService,
    public dialog: MatDialog,
    private imageUploader:ImageUploaderService
  ) { }

  ngOnInit(): void {
    this.buildFormIntro();
    this.buildFormLinks();
    this.buildFormPayme();

    this.userService.userData.subscribe(result => {
      console.log("USERDATA FOUND:",result,typeof result)
      // this.userData = Object.assign(this.defaultData,result)
      // this.userData = {...this.defaultData,...result}
      this. userData = _.merge(this.userData, result);
      this.userID = result.id;
      this.showLinks = this.userData.social_links[0].type != '';
      this.showPayme = this.userData.payme_links[0].type != '';
      this.imageSRC = this.userData.photo_url != '' ? this.userData.photo_url : this.imageSRC
      this.coverSRC = this.userData.cover_url != '' ? this.userData.cover_url : this.coverSRC
      console.log('USERDATA after Object.assign:',this.userData)
      this.populateFormIntro();
      if(this.showLinks) {
        this.populateFormLinks();
      }
      if(this.showPayme) {
        this.populateFormPayme();
      }
    });

  }

  ngOnDestroy():void {
    if(this.userSub) this.userSub.unsubscribe();
  }

  buildFormIntro() {
    this.validationFormIntro = this.formBuilder.group({
      first_name: this.formBuilder.control(null, []),
      last_name: this.formBuilder.control(null, []),
      phone: this.formBuilder.control(null, [Validators.minLength(10),Validators.pattern("^[0-9]*$")]),
      email: this.formBuilder.control(null, [Validators.email]),
      company: this.formBuilder.control(null, []),
      title: this.formBuilder.control(null, []),
      location: this.formBuilder.control(null, []),
      location2: this.formBuilder.control(null, []),
      image: this.formBuilder.control(null, []),
      image_cover: this.formBuilder.control(null, []),
      bio: this.formBuilder.control(null, []),
      website: this.formBuilder.control(null, []),

      phoneToggle: this.formBuilder.control(null, []),
      emailToggle: this.formBuilder.control(null, []),
      companyToggle: this.formBuilder.control(null, []),
      titleToggle: this.formBuilder.control(null, []),
      locationToggle: this.formBuilder.control(null, []),
      imageToggle: this.formBuilder.control(null, []),
      coverToggle: this.formBuilder.control(null, []),
      websiteToggle: this.formBuilder.control(null, []),
    });
  }

  buildFormLinks() {
    this.validationFormLinks = this.formBuilder.group({
      textToggle: this.formBuilder.control(null,[]),
      instagram: this.formBuilder.control(null,[]),
      venmo: this.formBuilder.control(null,[]),
      cashapp: this.formBuilder.control(null,[]),
      paypal: this.formBuilder.control(null,[]),
      snapchat: this.formBuilder.control(null,[]),
      youtube: this.formBuilder.control(null,[]),
      tiktok: this.formBuilder.control(null,[]),
      twitter: this.formBuilder.control(null,[]),
      twitch: this.formBuilder.control(null,[]),
      vimeo: this.formBuilder.control(null,[]),
      github: this.formBuilder.control(null,[]),
      linkedin: this.formBuilder.control(null,[]),
    })
  }

  buildFormPayme() {
    this.validationFormPayme = this.formBuilder.group({
      paymeToggle: this.formBuilder.control(null,[]),
      venmo: this.formBuilder.control(null,[]),
      cashapp: this.formBuilder.control(null,[]),
      paypal: this.formBuilder.control(null,[]),
      bitcoin: this.formBuilder.control(null,[]),
      dogecoin: this.formBuilder.control(null,[]),
      ethereum: this.formBuilder.control(null,[]),
    })
  }

  buildFormCustom() {
    this.validationFormCustom = this.formBuilder.group({
      custom_0_title: this.formBuilder.control(null,[]),
      custom_0_link: this.formBuilder.control(null,[]),
      custom_1_title: this.formBuilder.control(null,[]),
      custom_1_link: this.formBuilder.control(null,[]),
    })
  }

  refreshImages() {
    this.imageSRC = this.userData.photo_url == '' ? '/assets/broken_image_24px.svg' : this.userData.photo_url;
    this.coverSRC = this.userData.cover_url == '' ? '/assets/broken_image_24px.svg' : this.userData.cover_url;
  }

  populateFormIntro() {
    this.validationFormIntro.get('first_name').setValue(this.userData.first_name);
    this.validationFormIntro.get('last_name').setValue(this.userData.last_name);
    this.validationFormIntro.get('phone').setValue(this.userData.phone);
    this.validationFormIntro.get('email').setValue(this.userData.email);
    this.validationFormIntro.get('company').setValue(this.userData.company);
    this.validationFormIntro.get('title').setValue(this.userData.title);
    this.validationFormIntro.get('location').setValue(this.userData.location);
    this.validationFormIntro.get('location2').setValue(this.userData.location2);
    this.validationFormIntro.get('bio').setValue(this.userData.bio);
    this.validationFormIntro.get('website').setValue(this.userData.website);

    this.validationFormIntro.get('phoneToggle').setValue(this.userData.vc_settings.enable_phone);
    this.validationFormIntro.get('emailToggle').setValue(this.userData.vc_settings.enable_email);
    this.validationFormIntro.get('companyToggle').setValue(this.userData.vc_settings.enable_company);
    this.validationFormIntro.get('titleToggle').setValue(this.userData.vc_settings.enable_title);
    this.validationFormIntro.get('locationToggle').setValue(this.userData.vc_settings.enable_location);
    this.validationFormIntro.get('imageToggle').setValue(this.userData.vc_settings.enable_image);
    // this.validationFormIntro.get('coverToggle').setValue(this.userData.vc_settings.enable_cover);
    this.validationFormIntro.get('websiteToggle').setValue(this.userData.vc_settings.enable_website);
  }

  populateFormLinks() {
    for(let i = 0; i < this.userData.social_links.length; i++) {
      this.validationFormLinks.get(this.userData.social_links[i].type).setValue(this.userData.social_links[i].link)
    }
    this.validationFormLinks.get('textToggle').setValue(this.userData.show_socials_text);
  }

  populateFormPayme() {
    for(let i = 0; i < this.userData.payme_links.length; i++) {
      this.validationFormPayme.get(this.userData.payme_links[i].type).setValue(this.userData.payme_links[i].link)
    }
    this.validationFormPayme.get('paymeToggle').setValue(this.userData.payments_top);
  }

  populateFormCustom() {
    for(let i = 0; i < this.userData.custom_links.length; i++) {
      this.validationFormPayme.get(`custom_${i}_link`).setValue(this.userData.custom_links[i].link)
      this.validationFormPayme.get(`custom_${i}_title`).setValue(this.userData.custom_links[i].title)
    }
  }

  viewProfile() {
    if (this.userData.alte_premium && this.userData.custom_id_enabled && this.userData.custom_id != '') {
      this.router.navigate([`me/${this.userData.custom_id}`], { });
    } else {
      this.router.navigate([`me/${this.userID}`], { });
    }
  }

  getLink() {
    console.log('getLink clicked!');
    if (this.userData.alte_premium && this.userData.custom_id_enabled && this.userData.custom_id != '') {
      this.copyToClipboard(`${window.location.origin.toString()}/${this.userData.custom_id}`);
      // this.copyToClipboard(`${window.location.origin.toString()}/me/${this.userData.custom_id}`);
    } else {
      this.copyToClipboard(`${window.location.origin.toString()}/${this.userID}`);
      // this.copyToClipboard(`${window.location.origin.toString()}/me/${this.userID}`);
    }
  }

  goTheme() {
    console.log('goTheme clicked!');
    this.router.navigate([`me/edit/theme`], { });
  }

  goPay() {
    console.log('goPay clicked!');
    this.router.navigate([`me/edit/pay`], { });
  }

  viewSettings() {
    console.log('')
  }

  signOut() {
    this.authService.logout().then(result => {
      this.router.navigate(['login'])
    })
  }

  resetMessaging() {
    this.serverErrorIntro = false;
    this.errorMsgIntro = '';
  }

  onChangedIntro(field) {
    console.log('onChangedIntro',field);
    this.serverErrorLinks = false;
    this.errorMsgLinks = "";
    let value:any = this.validationFormIntro.get(field).value 
    return this.userService.saveUserInfo({[field]:value}).then(result => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.populateFormIntro();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
  }

  infoToggle(field) {
    console.log('onInfoToggle',field);
    this.serverErrorLinks = false;
    this.errorMsgLinks = "";
    let value = this.validationFormIntro.get(`${field}Toggle`).value;
    return this.userService.saveUserToggle(field,!value).then(() => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.populateFormIntro();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
  }

  visibilityToggleVC(field) {
    console.log('visibilityToggleVC',field);
    this.serverErrorLinks = false;
    this.errorMsgLinks = "";
    let value = this.userData.vc_settings[`enable_${field}`]
    return this.userService.saveUserToggle(field,!value).then(() => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.populateFormIntro();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
  }

  onIntroSubmit() {
    console.log('onInfoSubmit!')
    this.resetMessaging();

    let newData = {
      first_name:this.validationFormIntro.get('first_name').value,
      last_name:this.validationFormIntro.get('last_name').value,
      phone:this.validationFormIntro.get('phone').value,
      email:this.validationFormIntro.get('email').value,
      company:this.validationFormIntro.get('company').value,
      title:this.validationFormIntro.get('title').value,
      location:this.validationFormIntro.get('location').value,
      location2:this.validationFormIntro.get('location2').value,
      city:this.userData.city,
      bio:this.validationFormIntro.get('bio').value,
      website:this.validationFormIntro.get('website').value,
      vc_settings: {
        enable_phone: this.validationFormIntro.get('phoneToggle').value,
        enable_email: this.validationFormIntro.get('emailToggle').value,
        enable_title: this.validationFormIntro.get('titleToggle').value,
        enable_company: this.validationFormIntro.get('companyToggle').value,
        enable_location: this.validationFormIntro.get('locationToggle').value,
        enable_image: this.validationFormIntro.get('imageToggle').value,
        // enable_cover: this.validationFormIntro.get('coverToggle').value,
      }
    }

    this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.populateFormIntro();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
    // this.uploadPhoto();
    this.uploadCover();
  }

  linkTextToggle() {
    console.log('linkTextToggle')
    this.userService.saveUserInfo({'show_socials_text':!this.validationFormLinks.get('textToggle').value}).then(result => {
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Information Saved";
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Error: information not saved."  
    })
  }

  onChangedLinks(type,visible) {
    console.log('onChangedLinks',type);
    this.serverErrorLinks = false;
    this.errorMsgLinks = "";
    let link:any = this.validationFormLinks.get(type).value 
    let newData:any = {
      social_links:this.userData.social_links,
    }

    for(let i = 0; i < newData.social_links.length; i++) {
      console.log(newData.social_links[i].type, type)
      if(newData.social_links[i].type == type) {
        newData.social_links[i].link = link;
        newData.social_links[i].visible = visible;
      }
    }

    return this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Information Saved";
      this.populateFormLinks();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Error: information not saved."  
    })
  }

  onLinksSubmit() {
    console.log('onLinksSubmit');
    let newData:any = {
      social_links:this.userData.social_links,
      show_socials_text:this.validationFormLinks.get('textToggle').value
    }

    for(let i = 0; i < newData.social_links.length; i++) {
      console.log(newData.social_links[i].type,this.validationFormLinks.get(newData.social_links[i].type).value)
      newData.social_links[i].link = this.validationFormLinks.get(newData.social_links[i].type).value;
    }

    this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Information Saved";
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorLinks = true;
      this.errorMsgLinks = "Error: information not saved."  
    })
  }

  onChangedPayme(type,visible) {
    console.log('onChangedLinks',type);
    this.serverErrorLinks = false;
    this.errorMsgLinks = "";
    let link:any = this.validationFormPayme.get(type).value 
    let newData:any = {
      payme_links:this.userData.payme_links,
    }

    for(let i = 0; i < newData.payme_links.length; i++) {
      console.log(newData.payme_links[i].type, type)
      if(newData.payme_links[i].type == type) {
        newData.payme_links[i].link = link;
        newData.payme_links[i].visible = visible;
      }
    }

    return this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Information Saved";
      this.populateFormPayme();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Error: information not saved."  
    })
  }

  onPaymeSubmit() {
    console.log('onPaymeSubmit');
    let newData:any = {
      payme_links:this.userData.payme_links,
      payments_top:this.validationFormPayme.get('paymeToggle').value
    }

    for(let i = 0; i < newData.payme_links.length; i++) {
      console.log(newData.payme_links[i].type,this.validationFormPayme.get(newData.payme_links[i].type).value)
      newData.payme_links[i].link = this.validationFormPayme.get(newData.payme_links[i].type).value;
    }

    this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Information Saved";
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Error: information not saved."  
    })
  }

  onChangedCustom(order) {
    console.log('onChangedCustom',order);
    this.serverErrorCustom = false;
    this.errorMsgCustom = "";

    let newData:any = {
      custom_links: this.userData.custom_links
    }
    let link:any = this.validationFormPayme.get(`custom_${order}_link`).value 
    let title:any = this.validationFormPayme.get(`custom_${order}_title`).value 

    for(let i = 0; i < newData.custom_links.length; i++) {
      console.log(newData.payme_links[i].order, order)
      if(newData.custom_links[i].order == order) {
        newData.custom_links[i].link = link;
        newData.custom_links[i].title = title;
      }
    }

    return this.userService.saveUserInfo(newData).then(result => {
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Information Saved";
      this.populateFormPayme();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorPayme = true;
      this.errorMsgPayme = "Error: information not saved."  
    })
    console.log('onChangedCustom', newData)
  }

  onCustomSubmit() {
    console.log('onCustomSubmit');
    let newData:any = {
      custom_links: this.userData.custom_links
    }
    console.log('onCustomSubmit', newData)

    // for(let i = 0; i < newData.payme_links.length; i++) {
    //   console.log(newData.custom_links[i].type,this.validationFormPayme.get(`custom_${i}`).value)
    //   // newData.payme_links[i].link = this.validationFormPayme.get(`custom_${i}`).value;
    // }
  }

  addLink(type:string) {
    // add statements to make icon different if sm name doesnt match to icon
    let icon = type;
    let newLink = {
      type:type,
      order:this.userData.social_links.length,
      link:'',
      icon: icon,
      visible:true
    }
    this.userService.addLink(newLink)
    .then(result => {
      console.log('add link successful');
    }).catch(error => {
      console.log('error adding link', error);
    })
  }

  deleteLink(link) {
    this.userService.rmLink(link)
    .then(result => {
      console.log('remove link sucessful');
      // this.userData.social_links = 
      _.remove(this.userData.social_links, link);
    }).catch(error => {
      console.log('error removing link');
    })
  }

  addPayme(type:string) {
    // add statements to make icon different if sm name doesnt match to icon
    let icon = type;
    let newLink = {
      type:type,
      order:this.userData.payme_links.length,
      link:'',
      icon: icon,
      visible:true
    }
    this.userService.addPayme(newLink)
    .then(result => {
      console.log('add link successful');
    }).catch(error => {
      console.log('error adding link', error);
    })
  }

  deletePayme(payme) {
    this.userService.rmPayme(payme)
    .then(result => {
      console.log('remove payme sucessful');
      // this.userData.payme_links = 
      _.remove(this.userData.payme_links, payme);
    }).catch(error => {
      console.log('error removing payme');
    })
  }

  addCustom() {
    this.serverErrorCustom = false;
    this.errorMsgCustom = "";
    if (this.userData.custom_links.length >= 2) {
      console.log('max 2');
      this.serverErrorCustom = true;
      this.errorMsgCustom = "Maximum 2 Custom Links";
      return;
    }
    let newLink = {
      type:'',
      order:this.userData.custom_links.length,
      link:'',
      title:'',
      visible:true
    }
    this.userService.addCustom(newLink)
    .then(result => {
      console.log('add customlink successful');
    }).catch(error => {
      console.log('error adding customlink', error);
    })
  }

  deleteCustom(payme) {
    this.userService.rmCustom(payme)
    .then(result => {
      console.log('remove customlink sucessful');
      // this.userData.payme_links = 
      _.remove(this.userData.custom_links, payme);
    }).catch(error => {
      console.log('error removing customlink');
    })
  }

  deleteImage(type = '') {
    console.log('deleteImage!',type);
    let field = `${type}_url`
    return this.userService.saveUserInfo({[field]:''}).then(result => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.refreshImages();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
  }

  showLink(linkType:string) {
    // console.log('showlink',linkType,(this.userData.social_links).find(({ type }) => type == linkType) == undefined)
    return (this.userData.social_links).find(({ type }) => type == linkType) == undefined;
  }

  showPaymeLink(linkType:string) {
    // console.log('showlink',linkType,(this.userData.social_links).find(({ type }) => type == linkType) == undefined)
    return (this.userData.payme_links).find(({ type }) => type == linkType) == undefined;
  }
  
  autocompleteFocus() { 
    console.log('load places api autocomplete')
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          let location_obj:any = {}

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          location_obj.lat = place.geometry.location.lat();
          location_obj.lng = place.geometry.location.lng();

          console.log('PLACE: ');
          console.dir(place);
      
          let address = place.address_components;

          location_obj.street = address[0].long_name + " " + address[1].long_name;
          location_obj.street_sh = address[0].short_name + " " + address[1].short_name;
          location_obj.town = address[2].long_name;
          location_obj.city = address[3].long_name;
          location_obj.county = address[4].long_name;
          location_obj.state = address[5].long_name;
          location_obj.state_sh = address[5].short_name;
          location_obj.zip = address[7].long_name;

          let addressStr = address[0].long_name + " " + address[1].long_name + ",\n " + address[2].long_name + ", " + address[5].short_name + " " + address[7].long_name;
          
          // let parseObj = this.parseGoogleResponse(place.address_components);
          this.validationFormIntro.get('location').setValue(addressStr);
          this.userData.city = address[2].long_name + ", " + address[5].short_name;
          // this.validationForm.get('merchantAddress1').setValue(parseObj.address1);
          // this.validationForm.get('addressSearch').setValue(addObj.address1);
          // this.validationForm.get('merchantCity').setValue(parseObj.city);
          // this.selectState.selectedValue = parseObj.state;
          // this.validationForm.get('merchantZip').setValue(parseObj.zip);

          return this.userService.saveUserInfo({location_obj}).then(result => {
            this.serverErrorIntro = true;
            this.errorMsgIntro = "Information Saved";
            this.populateFormIntro();
          }).catch(error => {
            console.log('ERROR SAVING DATA',error)
            this.serverErrorIntro = true;
            this.errorMsgIntro = "Error: information not saved."  
          })

        });
      });
    });
  }

  parseGoogleResponse(components:any):any {
    let addObj:any = {};
    addObj.address1 = "";
    _.each(components, function(component:any) {
      _.each(component.types, function(type) {
        if (type === 'street_number') {
          addObj.address1 = component.long_name;
        }
        if (type === 'route') {
          if(addObj.address1 != "") {
            addObj.address1 += " " + component.long_name;
          } else {
            addObj.address1 = component.long_name;
          }
        }
        if (type === 'locality') {
          addObj.city = component.long_name;
        }
        if (type === 'administrative_area_level_1') {
          addObj.state = component.short_name;
        }
        if (type === 'postal_code') {
          addObj.zip = component.long_name;
        }
      })
    })
    console.log("PARSER RESPONSE");
    // console.dir(typeof addObj);
    return addObj.address1 + ", " + addObj.city + "" + addObj.state + addObj.zip;
  }

  getTitle(type) {
    if(type == 'instagram'){
      return 'Instagram Username'
    } else if(type == 'snapchat'){
      return 'Snapchat Username'
    } else if(type == 'tiktok'){
      return 'TikTok Username'
    } else if(type == 'github'){
      return 'GitHub Username'
    } else if(type == 'twitter'){
      return 'Twitter Username'
    } else if(type == 'youtube'){
      return 'YouTube Username'
    } else if(type == 'vimeo'){
      return 'Vimeo Username'
    } else if(type == 'twitch'){
      return 'Twitch Username'
    } else if(type == 'behance'){
      return 'Behance Username'
    } else if(type == 'deviantart'){
      return 'Deviant Art Username'
    } else if(type == 'untappd'){
      return 'Untappd Username'
    } else if(type == 'reddit'){
      return 'Reddit Username'
    } else if(type == 'facebook'){
      return 'Facebook Username'
    } else if(type == 'paypal'){
      return 'PayPal.Me Username'
    } else if(type == 'cashapp'){
      return '$Cashtag'
    } else if(type == 'bitcoin'){
      return 'Bitcoin Wallet Address'
    } else if(type == 'dogecoin'){
      return 'Dogecoin Wallet Address'
    } else if(type == 'ethereum'){
      return 'Ethereum Wallet Address'
    } else if(type == 'venmo'){
      return 'Venmo Link'
    } else if(type == 'linkedin'){
      return 'LinkedIn Username'
    } else if(type == 'spotify'){
      return 'Spotify Link'
    } else if(type == 'soundcloud'){
      return 'Soundcloud Username'
    } else if(type == 'bandcamp'){
      return 'Bandcamp Link'
    } else if(type == 'stack-overflow'){
      return 'Stack Overflow Profile Link'
    } else if(type == 'angellist'){
      return 'Angel List Username'
    }else if(type == 'custom'){
      return 'Custom Link'
    } else {
      return '-'
    }
  }

  copyToClipboard(textToCopy) {
    var textArea;

    console.log('link check:',textToCopy)
  
    function isOS() {
      //can use a better detection logic here
      return navigator.userAgent.match(/ipad|iphone/i);
    }
  
    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }
  
    function selectText() {
      var range, selection;
  
      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }
  
    function copyTo() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  
    createTextArea(textToCopy);
    selectText();
    copyTo();
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '250px',
      data: {type:data}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
    });
  }

  onProfileChange($event) {
    console.log("Profile change", $event);
    this.uploadPhoto();
  }

  uploadPhoto() {
    console.log('uploadPhotoStart');
    let file = this.userImage.data.image;
    this.imageUpload = new Upload(file);
    let fileName = "prof_image" + this.userID + ".jpg";

    let uploadTask:firebase.storage.UploadTask = this.imageUploader.pushUploadString(this.imageUpload, "user", fileName);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot:any) => {
          this.imageUpload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Bytes Transferred: " + this.imageUpload.progress);
      },
      (error) => {
          console.log("Upload Task Error:", error);
      },
      () => {
          // upload success
          uploadTask.snapshot.ref.getDownloadURL()
          .then(downloadURL => {
              this.imageUpload.url = downloadURL;
              this.imageUpload.name = this.imageUpload.file.name;
              //upload.ref = uploadTask.snapshot.ref.name;
              console.log("Updating User with Image URL", this.userID, downloadURL);
              this.userService.updateUserImage(this.userID, downloadURL);
              this.imageSRC = downloadURL;
              console.log("DEAL UPLOAD TASK COMPLETE", downloadURL);
              this.userImage.imageUpdated = false;
              // this.dealImageAlreadyUploaded = true;
          });
      });
  }

  onCoverChange($event) {
    console.log('Cover Change',$event);
    this.uploadCover();
    return this.userService.saveUserToggle('cover',true).then(() => {
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Information Saved";
      this.populateFormIntro();
    }).catch(error => {
      console.log('ERROR SAVING DATA',error)
      this.serverErrorIntro = true;
      this.errorMsgIntro = "Error: information not saved."  
    })
  }

  uploadCover() {
    console.log('uploadCoverStart');
    let file = this.coverImage.data.image;
    this.imageUpload = new Upload(file);
    let fileName = "cover_image" + this.userID + ".jpg";

    let uploadTask:firebase.storage.UploadTask = this.imageUploader.pushUploadString(this.imageUpload, "user", fileName);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot:any) => {
          this.imageUpload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Bytes Transferred: " + this.imageUpload.progress);
      },
      (error) => {
          console.log("Upload Task Error:", error);
      },
      () => {
          // upload success
          uploadTask.snapshot.ref.getDownloadURL()
          .then(downloadURL => {
              this.imageUpload.url = downloadURL;
              this.imageUpload.name = this.imageUpload.file.name;
              //upload.ref = uploadTask.snapshot.ref.name;
              console.log("Updating User with Image URL", this.userID, downloadURL);
              this.userService.updateCoverImage(this.userID, downloadURL);
              this.coverSRC = downloadURL;
              console.log("DEAL UPLOAD TASK COMPLETE", downloadURL);
              this.coverImage.imageUpdated = false;
              // this.dealImageAlreadyUploaded = true;
          });
      });
  }

}
