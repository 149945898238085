<div class="row image-component">
  <div>
    <input #fileInput type="file" accept="image/*" [hidden]="true" (change)="fileChangeListener($event)">
    <!-- <mat-form-field floatLabel="never" class="full-width" dividerColor="primary">  -->
      <input matInput placeholder="Choose Photo..." #photoUser name="photoUser" type="text" value="" disabled [hidden]="true"> 
      
    <!-- </mat-form-field>	 -->
  </div>

  <div class="image-container upload">
    <div class="user-image" (click)="uploadClick($event)">
      <img alt="avatar" class="" [src]="data.image" style="max-height:100px; min-height: 50px;"> 
    </div>		
  </div>

  <div>
    <button (click)="uploadClick($event)" class="mat-raised-button image-component-button" color="" mat-raised-button="" ng-reflect-color="primary"
    style="border: none; box-shadow: none; border-radius: 5px; height: 40px;">
      <span class="mat-button-wrapper">
        <!-- <mat-icon>insert_photo</mat-icon> -->
        SELECT IMAGE...</span>
      <div class="mat-button-ripple mat-ripple" mat-ripple="" ng-reflect-trigger="[object HTMLButtonElement]" ng-reflect-centered="false" ng-reflect-disabled="false">				
      </div> 
      <div class="mat-button-focus-overlay"></div>
    </button>
    
  </div>
</div>