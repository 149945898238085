<div class="edit-theme edit-page">
    <mat-toolbar>
        <span><mat-icon (click)="goBack()">arrow_back_ios</mat-icon>&nbsp;Customize</span>
        <span class="example-spacer"></span>
        <button mat-mini-fab color="primary"  [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewProfile()"><mat-icon>preview</mat-icon><span>Preview Profile</span></button>
            <!-- <button mat-menu-item (click)="getLink()"><mat-icon>link</mat-icon><span>Get My Link</span></button> -->
            <!-- <button mat-menu-item (click)="goTheme()"><mat-icon>link</mat-icon><span>Customize</span></button> -->
            <button mat-menu-item (click)="goEdit()"><mat-icon>edit</mat-icon><span>Edit Info</span></button>
            <button mat-menu-item (click)="goPay()"><mat-icon>credit_card</mat-icon><span>Payment</span></button>
            <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
            <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
        </mat-menu>
    </mat-toolbar>
    <div class="toolbar-spacing"></div>
    <div class="row">
        <div class="form-container">
            <h1>Custom ID
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('custID')">help</mat-icon>
                </small>
            </h1>
            <form [formGroup]="validationFormTheme">
                <mat-form-field appearance="fill" class="full-width-vcard" hintLabel=""> 
                    <mat-label></mat-label>
                    <input matInput formControlName="custom_id" placeholder="myalte" type="text" (change)="onChangedTheme('custom_id')">
                    <span matPrefix>alte.app/&nbsp;</span>
                </mat-form-field>
                <div class="vcard-toggle no-label">
                    <!-- <mat-label>{{validationFormTheme.get('custom_id_Toggle').value ? 'Enabled':'Disabled'}}</mat-label> -->
                    <mat-slide-toggle formControlName="custom_id_Toggle" (click)="themeToggle('custom_id')"></mat-slide-toggle>
                </div>
            </form>
        </div>
    </div>
</div>