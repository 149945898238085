import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-passthrough',
  templateUrl: './passthrough.component.html',
  styleUrls: ['./passthrough.component.scss']
})
export class PassthroughComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
