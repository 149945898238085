<div class="login-page">
    <div class="row">
        <h1 class="app-name">
            Alte
        </h1>
        <div class="form-container" [hidden]="!showLogin">
            <h3>Login</h3>
            <form [formGroup]="validationFormEmail" (ngSubmit)="loginByEmailClick()">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="requiredEmail" placeholder="example@email.com">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="requiredPassword" placeholder="6 Characters Minimum"
                    formControlName="requiredPassword" type="password">
                </mat-form-field>
                <button (click)="onLogin()" mat-raised-button [disabled]="!validationFormEmail.valid || formSubmitted" type="button" color='primary'>
                    Log In
                </button>
            </form>
        </div>
        <div class="form-container" [hidden]="showLogin">
            <h3>Sign Up</h3>
            <form [formGroup]="validationFormSignup" (ngSubmit)="registerByEmailClick()">
                <mat-form-field appearance="fill">
                    <mat-label>Full Name</mat-label>
                    <input matInput formControlName="registerName" placeholder="Jane">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="registerEmail" placeholder="example@email.com">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="requiredPassword" placeholder="6 Characters Minimum" type="password">
                </mat-form-field>

                <mat-checkbox formControlName="restaurantCheck" color="primary" style="padding-bottom: 20px;">
                    I am signing up as a restaurant
                </mat-checkbox>

                <div class="padding"></div>

                <mat-form-field appearance="fill" *ngIf="validationFormSignup.get('restaurantCheck').value">
                    <mat-label>Restaurant Name</mat-label>
                    <input matInput formControlName="restaurantName" placeholder="Restaurant Name" type="text">
                </mat-form-field>

                <mat-divider style="margin: 7px 0px 15px;"></mat-divider>

                <mat-checkbox [checked]="false" required formControlName="requiredTerms" color="primary" style="padding-bottom: 20px;">
                    I agree to the <a routerLink="/" alt="Terms &amp; Conditions">Terms &amp; Conditions</a> 
                    <!-- and <a routerLink="/" alt="Privacy Policy">Privacy Policy.</a> -->
                </mat-checkbox>

                <button mat-raised-button [disabled]="!validationFormSignup.valid" type="submit" #submitBtn id="btn-submit-reg" color='primary'>
                    Sign Up
                </button>
            </form>
        </div>
        <p (click)="toggleForm()">{{showLogin ? "Not registered? Sign Up" : "Already a member? Log In"}}</p>
        <div *ngIf="serverError" class="alert alert-danger">
            {{errorMsg}}
        </div>
    </div>
</div>