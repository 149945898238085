import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

import * as _ from 'lodash';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.component.html',
  styleUrls: ['./menu-edit.component.scss','../rest-edit.component.scss']
})
export class MenuEditComponent implements OnInit {

  public userData:any = {};
  private userID:string = '';
  private userSub:Subscription;
  private menuID:string = '';

  menuForm:FormGroup;


  public submenusdb;
  public submenusNum:number = 0

  constructor(
    private authService:AuthService,
    private router:Router,
    private route: ActivatedRoute,
    private userService:UserService,
    private formBuilder:FormBuilder
  ) { }

  ngOnInit(): void {
    this.menuID = this.route.snapshot.params['id'];

    // this.userService.userData.subscribe(result => {
    //   console.log("USERDATA FOUND:",result,typeof result)
    //   // this.userData = Object.assign(this.defaultData,result)
    //   // this.userData = {...this.defaultData,...result}
    //   this.userData = _.merge(this.userData, result);
    //   this.userID = result.id;
    //   console.log('USERDATA after Object.assign:',this.userData)
     
    // });

    this.userService.getSubMenus(this.menuID).subscribe((result:any) => {
      this.submenusNum = result.length;
      this.submenusdb = result;
      this.buildForm();
    });
    // console.log("SUBMENUS",this.submenusdb)
  }

  buildForm() {
    this.menuForm = this.formBuilder.group({
      submenusForm: this.formBuilder.array([]),
      itemsForm: this.formBuilder.array([ ])
    })
    for (let i = 0; i < this.submenusNum; i++) {
      this.addSubMenuForm();
    }
  }

  goBack() {
    console.log('goBack clicked!');
    this.router.navigate([`r/edit`], { });
  }

  signOut() {
    this.authService.logout().then(result => {
      this.router.navigate(['login'])
    })
  }

  onSubMenuTitleChange(i,menu) {
    let title = this.submenusForm.get(`${i}`).value
    console.log("onchange",title,menu);
    this.userService.updateSubMenu(this.menuID,menu)
    .then(() => {
      console.log('update menu successful');
    }).catch(error => {
      console.log('error updating menu', error);
    })
  }

  addSubMenu() {
    let order = this.submenusdb ? this.submenusdb.length : 0;
    console.log(order);
    this.userService.addSubMenu(order,this.menuID)
    .then(() => {
      console.log('add menu successful');
      this.addSubMenuForm();
    }).catch(error => {
      console.log('error adding menu', error);
    })
  }

  addSubMenuForm() {
    this.submenusForm.push(this.formBuilder.control(''));
  }

  createSubMenuForm(index = 0): FormGroup {
    return this.formBuilder.group({
      [`sub-title-${index}`]:'',
      [`sub-desc-${index}`]:'',
    })
  }

  addSubMenuItem(id) {
    console.log('addSubMenuItem',id);
  }

  get submenusForm() {
    return this.menuForm.get('submenusForm') as FormArray;
  }

}
