<div class="edit-page">
    <mat-toolbar>
        <span>Menu Editing Home</span>
        <span class="example-spacer"></span>
        <button mat-mini-fab color="primary"  [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewMenu()"><mat-icon>preview</mat-icon><span>Preview Menu</span></button>
            <button mat-menu-item (click)="getLink()"><mat-icon>link</mat-icon><span>Get Menu Link</span></button>
            <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
            <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
        </mat-menu>
    </mat-toolbar>
    <div class="toolbar-spacing"></div>
    <div class="row">
        <div class="form-container">
            <h1>Business Information
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('')">help</mat-icon>
                </small>
            </h1>
            <form [formGroup]="validationFormInfo">

                <div style="margin-top: 10px;">Upload your logo: (recommend *.png with transparency)</div>
                <image-component #logoImage [(imageSRC)]="imageSRC" [(imageCROP)]="cover" class="full-width"></image-component>
                <!-- <div class="vcard-toggle" style="height: 100px;"></div> -->

                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Restaurant Name</mat-label>
                    <input matInput formControlName="name" placeholder="Bacari PDR" type="text">
                </mat-form-field>

                <mat-form-field appearance="fill" class="location-width" style="height: 100px;" hintLabel="">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="location" placeholder="111 Street Ave, City, ST, 95135" #search type="text" (focus)="autocompleteFocus()"></textarea>
                </mat-form-field>
                
                <mat-form-field appearance="fill" class="unit-num-width" style="position: relative; bottom: 15px;">
                    <mat-label>Suite #</mat-label>
                    <input matInput formControlName="location2" placeholder="SUITE 111" #search type="text">
                </mat-form-field>

                <mat-form-field appearance="fill" class="full-width-vcard" hintLabel="Numbers only, minimum 10 digits."> 
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone" placeholder="5551237890" type="tel">
                </mat-form-field>
                <mat-slide-toggle class="vcard-toggle" formControlName="phoneToggle"></mat-slide-toggle>

                <div class="save-container">
                    <button mat-icon-button color="primary"  [matMenuTriggerFor]="addInfoMenu" class="add-btn">Add Field<mat-icon>add</mat-icon></button>
                    <mat-menu #addInfoMenu="matMenu">
                        <button mat-menu-item (click)="viewMenu()"><mat-icon>preview</mat-icon><span>Placeholder</span></button>
                    </mat-menu>
                    <div class="alert alert-danger save-alert">
                        <span *ngIf="serverErrorInfo">{{errorMsgInfo}}</span>
                    </div>
                    <button class="save-btn" (click)="onInfoSubmit()" mat-raised-button [disabled]="!validationFormInfo.valid || formSubmittedInfo" type="button" color="primary">
                        <span>Save</span>
                    </button>
                </div>

            </form>
        </div>
    </div>
    <div class="row">
        <div class="menu-buttons">
            <div class="menu-button" *ngFor="let menu of (menus | async)">
                <button (click)="editMenu(menu)"><span>{{menu.title}}</span></button>
                <mat-slide-toggle [checked]="menu.visible" (change)="menuToggle(menu)"></mat-slide-toggle>
                <mat-icon (click)="deleteMenu(menu)">delete</mat-icon>
            </div>
            <button mat-icon-button color="primary" class="add-btn" (click)="addMenu()">Add Menu<mat-icon>add</mat-icon></button>
        </div>
    </div>
    <div class="row">
        <div class="form-container">
            <div style="margin-top: 10px;">Add a cover photo: (recommend rectangular image)</div>
            <image-component #coverImage [(imageSRC)]="coverSRC" [(imageCROP)]="cover" class="full-width"></image-component>
            <!-- <div class="vcard-toggle" style="height: 100px;"></div>     -->
        </div>
    </div>
</div>