import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxVcardModule } from "ngx-vcard";

import { EditorModule } from "@tinymce/tinymce-angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageModule } from '../image/image.module';
import { MatDividerModule } from '@angular/material/divider';
import { UserThemeComponent } from './theme/theme.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserPaymentComponent } from './payment/payment.component';





@NgModule({
  declarations: [
    EditComponent, 
    HelpDialogComponent,
    UserThemeComponent,
    UserPaymentComponent
  ],
  imports: [
    CommonModule,
    NgxVcardModule,
    BrowserAnimationsModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatExpansionModule,
    MatMenuModule,
    EditorModule,
    MatDialogModule,
    ImageModule
  ],
  entryComponents: [
    HelpDialogComponent
  ]
})
export class EditModule { }
