import { Injectable } from '@angular/core';

import * as voucher_codes from 'voucher-code-generator';

const characters = "ABCDEFGHJKMNPQRSTUVWXY23456789";
const numbers = "1234567890";


@Injectable({
  providedIn: 'root'
})
export class InvitecodeService {

  constructor() { }

  generateCode():string {
        let code = "default";

        code = voucher_codes.generate({
            length: 7,
            count: 1,
            charset: characters+numbers
        })[0]

        return code.toUpperCase();
    }
}
