<mat-toolbar>
    <span><mat-icon (click)="goBack()">arrow_back_ios</mat-icon>&nbsp;Payment Settings</span>
    <span class="example-spacer"></span>
    <button mat-mini-fab color="primary"  [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="viewProfile()"><mat-icon>preview</mat-icon><span>Preview Profile</span></button>
        <!-- <button mat-menu-item (click)="getLink()"><mat-icon>link</mat-icon><span>Get My Link</span></button> -->
        <button mat-menu-item (click)="goEdit()"><mat-icon>edit</mat-icon><span>Edit Info</span></button>
        <button mat-menu-item (click)="goTheme()"><mat-icon>link</mat-icon><span>Customize</span></button>
        <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
        <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
    </mat-menu>
</mat-toolbar>
<div class="edit-pay">
    <div class="toolbar-spacing"></div>
    <form method="POST" action="/create-customer-portal-session">
        <button type="submit">Manage billing</button>
    </form>
</div>