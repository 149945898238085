<div class="edit-page">
    <mat-toolbar>
        <span>Edit My Information</span>
        <span class="example-spacer"></span>
        <button mat-mini-fab color="primary"  [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewProfile()"><mat-icon>preview</mat-icon><span>Preview Profile</span></button>
            <button mat-menu-item (click)="getLink()"><mat-icon>link</mat-icon><span>Get My Link</span></button>
            <button mat-menu-item (click)="goTheme()"><mat-icon>architecture</mat-icon><span>Customize</span></button>
            <button mat-menu-item (click)="goPay()"><mat-icon>credit_card</mat-icon><span>Payment</span></button>
            <button mat-menu-item (click)="signOut()"><mat-icon>exit_to_app</mat-icon><span>Sign Out</span></button>
            <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
        </mat-menu>
    </mat-toolbar>
    <div class="toolbar-spacing"></div>
    <div class="row">
        <div class="form-container">
            <h1>Contact Card Info
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('vcard')">help</mat-icon>
                </small>
            </h1>
            <form [formGroup]="validationFormIntro">

                <mat-form-field appearance="fill" class="half-width">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="first_name" placeholder="X Æ A-Xii" type="text"
                    (change)="onChangedIntro('first_name')">
                </mat-form-field>
                <mat-form-field appearance="fill" class="half-width rm-margin">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="last_name" placeholder="Musk" type="text"
                    (change)="onChangedIntro('last_name')">
                </mat-form-field>
                
                <mat-form-field appearance="fill" class="full-width-vcard" hintLabel="Numbers only, minimum 10 digits."> 
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone" placeholder="5551237890" type="tel"
                    inputmode="tel" (change)="onChangedIntro('phone')">
                </mat-form-field>
                <div class="vcard-toggle">
                    <mat-label>vCard {{validationFormIntro.get('phoneToggle').value ? 'Show':'Hide'}}</mat-label>
                    <mat-slide-toggle formControlName="phoneToggle" (click)="infoToggle('phone')"></mat-slide-toggle>
                </div>

                <mat-form-field appearance="fill" class="full-width-vcard">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="example@email.com" type="email"
                    inputmode="email" (change)="onChangedIntro('email')">
                </mat-form-field>
                <div class="vcard-toggle">
                    <mat-label>vCard {{validationFormIntro.get('emailToggle').value ? 'Show':'Hide'}}</mat-label>
                    <mat-slide-toggle formControlName="emailToggle" (click)="infoToggle('email')"></mat-slide-toggle>
                </div>

                <mat-form-field appearance="fill" class="full-width-vcard">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" placeholder="Developer/Owner" type="text"
                    (change)="onChangedIntro('title')">
                </mat-form-field>
                <div class="vcard-toggle">
                    <mat-label>vCard {{validationFormIntro.get('titleToggle').value ? 'Show':'Hide'}}</mat-label>
                    <mat-slide-toggle formControlName="titleToggle" (click)="infoToggle('title')"></mat-slide-toggle>
                </div>

                <mat-form-field appearance="fill" class="full-width-vcard">
                    <mat-label>Company</mat-label>
                    <input matInput formControlName="company" placeholder="Alte App" type="text"
                    (change)="onChangedIntro('company')">
                </mat-form-field>
                <div class="vcard-toggle">
                    <mat-label>vCard {{validationFormIntro.get('companyToggle').value ? 'Show':'Hide'}}</mat-label>
                    <mat-slide-toggle formControlName="companyToggle" (click)="infoToggle('company')"></mat-slide-toggle>
                </div>

                <mat-form-field appearance="fill" class="full-width-vcard">
                    <mat-label>Website</mat-label>
                    <input matInput formControlName="website" placeholder="yourSite.com" type="url"
                    inputmode="url" (change)="onChangedIntro('website')">
                </mat-form-field>
                <div class="vcard-toggle">
                    <mat-label>vCard {{validationFormIntro.get('websiteToggle').value ? 'Show':'Hide'}}</mat-label>
                    <mat-slide-toggle formControlName="websiteToggle" (click)="infoToggle('website')"></mat-slide-toggle>
                </div>

                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>Profile Image</mat-panel-title>
                            <mat-panel-description></mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="full-width">
                            <div style="margin-top: 10px;">Add a profile photo:</div>
                                <image-component #userImage [(imageSRC)]="imageSRC" [(imageCROP)]="square" class="full-width-vcard" (onImageSelected)="onProfileChange($event)"></image-component>
                            <div class="vcard-toggle" style="height: 100px; margin: 10px 0px; width: 19%;">
                                <mat-label>vCard {{validationFormIntro.get('imageToggle').value ? 'Show':'Hide'}}</mat-label>
                                <mat-slide-toggle formControlName="imageToggle" style="margin: 0px 0px 20px;" (click)="infoToggle('image')"></mat-slide-toggle>
                                <mat-icon (click)="deleteImage('photo')">delete</mat-icon>
                            </div>
                        </div>                
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="row">
                    <h1>Profile Info
                        <small class="vcard-toggle">
                            <mat-icon (click)="openDialog('profile')">help</mat-icon>
                        </small>
                    </h1>
                    <mat-accordion>
                        <!-- <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>Profile Image</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="full-width">
                                <div style="margin-top: 10px;">Add a profile photo:</div>
                                    <image-component #userImage [(imageSRC)]="imageSRC" [(imageCROP)]="square" class="full-width-vcard" (onImageSelected)="onProfileChange($event)"></image-component>
                                <div class="vcard-toggle" style="height: 100px;">
                                    <mat-label>vCard {{validationFormIntro.get('phoneToggle').value ? 'Show':'Hide'}}</mat-label>
                                    <mat-slide-toggle formControlName="imageToggle" style="margin: 30px 0px;"></mat-slide-toggle>
                                    <mat-icon (click)="deleteImage('photo')">delete</mat-icon>
                                </div>
                            </div>                
                        </mat-expansion-panel> -->
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>Cover Image</mat-panel-title>
                                <mat-panel-description></mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="full-width">
                                <div style="margin-top: 10px;">Add a cover photo:</div>
                                <image-component #coverImage [(imageSRC)]="coverSRC"  [(imageCROP)]="cover" class="full-width-vcard" (onImageSelected)="onCoverChange($event)"></image-component>
                                <div class="vcard-toggle" style="height: 100px;">
                                    <mat-icon (click)="deleteImage('cover')">delete</mat-icon>
                                    <br><br>
                                    <mat-icon (click)="visibilityToggleVC('cover')">{{userData.vc_settings.enable_cover ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </div>
                            </div>                
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <mat-form-field appearance="fill" class="location-width" style="height: 100px;" hintLabel="Only CITY is shown on your profile.">
                    <mat-label>Location</mat-label>
                    <textarea matInput formControlName="location" placeholder="111 Street Ave, City, ST, 95135" #search type="text" (focus)="autocompleteFocus()"></textarea>
                </mat-form-field>
                <!-- <mat-slide-toggle class="vcard-toggle" formControlName="locationToggle" style="right: 15px;"></mat-slide-toggle> -->
                
                <mat-form-field appearance="fill" class="unit-num-width" style="position: relative; bottom: 15px;">
                    <mat-label>Unit #</mat-label>
                    <input matInput formControlName="location2" placeholder="APT 111" #search type="text"
                    (change)="onChangedIntro('location2')">
                </mat-form-field>

                <editor apiKey="{{tinyMCEkey}}"						
                formControlName="bio" name="deal-description"
                id="deal-description" #dealDesc type="text" value=""
                required

                initialValue=""
                [init]="{
                    height: 200,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print',
                    'preview anchor searchreplace visualblocks code',
                    'fullscreen insertdatetime media table paste',
                    'help wordcount'
                    ],
                    toolbar:
                    'bold italic underline | link | \
                    bullist numlist outdent indent'
                }"
                >
                </editor>
                <mat-hint align="start"><i>Bio and extra links can be placed here</i></mat-hint>
                
                <!-- <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Location</mat-label>
                    <input matInput formControlName="location" placeholder="Los Angeles" type="text">
                </mat-form-field> -->

                <div class="save-container">
                    <!-- <button mat-icon-button color="primary"  [matMenuTriggerFor]="addInfoMenu" class="add-btn">Add Field<mat-icon>add</mat-icon></button> -->
                    <mat-menu #addInfoMenu="matMenu">
                        <button mat-menu-item (click)="viewProfile()"><mat-icon>preview</mat-icon><span>Preview Profile</span></button>
                        <!-- <button mat-menu-item (click)="viewSettings()"><mat-icon>settings</mat-icon><span>User Settings</span></button> -->
                    </mat-menu>
                    <div class="alert alert-danger save-alert">
                        <span *ngIf="serverErrorIntro">{{errorMsgIntro}}</span>
                    </div>
                    <!-- <button class="save-btn" (click)="onIntroSubmit()" mat-raised-button [disabled]="!validationFormIntro.valid || formSubmittedIntro" type="button" color="primary">
                        <span>Save</span>
                    </button> -->
                </div>
            </form>
        </div>
    </div>
    <div class="row" *ngIf="false">
        <div class="form-container">
            <h1>Custom Links
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('customlinks')">help</mat-icon>
                </small>
            </h1>
            <form [formGroup]="validationFormCustom">
                <div class="full-width" *ngFor="let custom of userData.custom_links" style="display: flex;">
                    <div class="full-width-vcard">
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Display Title</mat-label>
                            <input matInput formControlName="custom_{{custom.order}}_title" type="text" (change)="onChangedCustom(custom.order)">
                            <span matPrefix><mat-icon>title</mat-icon></span>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Custom Link</mat-label>
                            <input matInput formControlName="custom_{{custom.order}}_link" type="text">
                            <span matPrefix><mat-icon>link</mat-icon></span>
                        </mat-form-field>
                    </div>
                    <div class="vcard-toggle" style="height: 120px; line-height: 120px;">
                        <mat-icon (click)="custom.visible = !custom.visible">{{custom.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <mat-icon (click)="deleteCustom(custom)">delete</mat-icon>
                    </div>
                </div>
                <div class="save-container">
                    <button mat-icon-button color="primary" (click)="addCustom()" class="add-btn">Add <mat-icon>add</mat-icon></button>
                    <div class="alert alert-danger save-alert">
                        <span *ngIf="serverErrorCustom">{{errorMsgCustom}}</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="form-container">
            <h1>Social Links
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('links')">help</mat-icon>
                </small>
            </h1>
        
            <form [formGroup]="validationFormLinks">
                <div class="toggle-field" [hidden]=true>
                    <div class="left-text">Show Text Next to Icon</div>
                    <mat-slide-toggle class="right-toggle" formControlName="textToggle" (click)="linkTextToggle()"></mat-slide-toggle>
                </div>
                <div [hidden]="!showLinks">
                    <mat-form-field appearance="fill" class="full-width" *ngFor="let social of userData.social_links" [hidden]="social.empty == true">
                        <mat-label>{{getTitle(social.type)}}</mat-label>
                        <input matInput formControlName="{{social.type}}" type="text" (change)="onChangedLinks(social.type,social.visible)">
                        <div matSuffix>
                            <button mat-icon-button  (click)="onChangedLinks(social.type,!social.visible)" [attr.aria-label]="'Show/Hide'" [attr.aria-pressed]="hide" style="display: inline-block;">
                                <mat-icon>{{social.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteLink(social)" [attr.aria-label]="'Delete Field'" [attr.aria-pressed]="hide" style="display: inline-block;">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-form-field>
                </div>

                <div class="save-container">
                    <button mat-icon-button color="primary"  [matMenuTriggerFor]="addLinkMenu" class="add-btn">Add <mat-icon>add</mat-icon></button>
                    <mat-menu #addLinkMenu="matMenu">
                        <button mat-menu-item (click)="addLink('custom')" *ngIf="showLink('custom')"><i class="fas fa-link"></i>&nbsp;&nbsp;<span>Custom Link</span></button>
                        <button mat-menu-item (click)="addLink('instagram')" *ngIf="showLink('instagram')"><i class="fab fa-instagram"></i>&nbsp;&nbsp;<span>Instagram</span></button>
                        <button mat-menu-item (click)="addLink('snapchat')" *ngIf="showLink('snapchat')"><i class="fab fa-snapchat"></i>&nbsp;&nbsp;<span>Snapchat</span></button>
                        <button mat-menu-item (click)="addLink('facebook')" *ngIf="showLink('facebook')"><i class="fab fa-facebook"></i>&nbsp;&nbsp;<span>Facebook</span></button>
                        <button mat-menu-item (click)="addLink('tiktok')" *ngIf="showLink('tiktok')"><i class="fab fa-tiktok"></i>&nbsp;&nbsp;<span>TikTok</span></button>
                        <button mat-menu-item (click)="addLink('twitter')" *ngIf="showLink('twitter')"><i class="fab fa-twitter"></i>&nbsp;&nbsp;<span>Twitter</span></button>
                        <button mat-menu-item (click)="addLink('twitch')" *ngIf="showLink('twitch')"><i class="fab fa-twitch"></i>&nbsp;&nbsp;<span>Twitch</span></button>
                        <button mat-menu-item (click)="addLink('youtube')" *ngIf="showLink('youtube')"><i class="fab fa-youtube"></i>&nbsp;&nbsp;<span>YouTube</span></button>
                        <button mat-menu-item (click)="addLink('vimeo')" *ngIf="showLink('vimeo')"><i class="fab fa-vimeo"></i>&nbsp;&nbsp;<span>Vimeo</span></button>
                        <button mat-menu-item (click)="addLink('spotify')" *ngIf="showLink('spotify')"><i class="fab fa-spotify"></i>&nbsp;&nbsp;<span>Spotify</span></button>
                        <button mat-menu-item (click)="addLink('soundcloud')" *ngIf="showLink('soundcloud')"><i class="fab fa-soundcloud"></i>&nbsp;&nbsp;<span>Soundcloud</span></button>
                        <button mat-menu-item (click)="addLink('bandcamp')" *ngIf="showLink('bandcamp')"><i class="fab fa-bandcamp"></i>&nbsp;&nbsp;<span>bandcamp</span></button>
                        <button mat-menu-item (click)="addLink('reddit')" *ngIf="showLink('reddit')"><i class="fab fa-reddit"></i>&nbsp;&nbsp;<span>Reddit</span></button>
                        <button mat-menu-item (click)="addLink('untappd')" *ngIf="showLink('untappd')"><i class="fab fa-untappd"></i>&nbsp;&nbsp;<span>Untappd</span></button>
                        <button mat-menu-item (click)="addLink('angellist')" *ngIf="showLink('angellist')"><i class="fab fa-angellist"></i>&nbsp;&nbsp;<span>Angel List</span></button>
                        <button mat-menu-item (click)="addLink('behance')" *ngIf="showLink('behance')"><i class="fab fa-behance"></i>&nbsp;&nbsp;<span>Behance</span></button>
                        <button mat-menu-item (click)="addLink('deviantart')" *ngIf="showLink('deviantart')"><i class="fab fa-deviantart"></i>&nbsp;&nbsp;<span>Deviant Art</span></button>
                        <button mat-menu-item (click)="addLink('github')" *ngIf="showLink('github')"><i class="fab fa-github"></i>&nbsp;&nbsp;<span>GitHub</span></button>
                        <button mat-menu-item (click)="addLink('stack-overflow')" *ngIf="showLink('stack-overflow')"><i class="fab fa-stack-overflow"></i>&nbsp;&nbsp;<span>stackoverflow</span></button>
                        <button mat-menu-item (click)="addLink('linkedin')" *ngIf="showLink('linkedin')"><i class="fab fa-linkedin"></i>&nbsp;&nbsp;<span>LinkedIn</span></button>
                    </mat-menu>
                    <div class="alert alert-danger save-alert">
                        <span *ngIf="serverErrorLinks">{{errorMsgLinks}}</span>
                    </div>
                    <!-- <button class="save-btn" (click)="onLinksSubmit()" mat-raised-button [disabled]="!validationFormLinks.valid || formSubmittedLinks || !showLinks" type="button" color="primary">
                        <span>Save</span>
                    </button> -->
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="form-container">
            <h1>Payment Links
                <small class="vcard-toggle">
                    <mat-icon (click)="openDialog('payme')">help</mat-icon>
                </small>
            </h1>
        
            <form [formGroup]="validationFormPayme">
                <div class="toggle-field" [hidden]="true">
                    <div class="left-text">Display on top right</div>
                    <mat-slide-toggle class="right-toggle" formControlName="paymeToggle"></mat-slide-toggle>
                </div>
                <div [hidden]="!showPayme">
                    <mat-form-field appearance="fill" class="full-width" *ngFor="let social of userData.payme_links" [hidden]="social.empty == true">
                        <mat-label>{{getTitle(social.type)}}</mat-label>
                        <input matInput formControlName="{{social.type}}" type="text" (change)="onChangedPayme(social.type,social.visible)">
                        <div matSuffix>
                            <button mat-icon-button  (click)="onChangedPayme(social.type,!social.visible)" [attr.aria-label]="'Show/Hide'" [attr.aria-pressed]="hide" style="display: inline-block;">
                                <mat-icon>{{social.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deletePayme(social)" [attr.aria-label]="'Delete Field'" [attr.aria-pressed]="hide" style="display: inline-block;">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-form-field>
                </div>

                <div class="save-container">
                    <button mat-icon-button color="primary"  [matMenuTriggerFor]="addPaymeMenu" class="add-btn">Add <mat-icon>add</mat-icon></button>
                    <mat-menu #addPaymeMenu="matMenu">
                        <button mat-menu-item (click)="addPayme('paypal')" *ngIf="showPaymeLink('paypal')"><i class="fab fa-paypal"></i>&nbsp;&nbsp;<span>PayPal</span></button>
                        <button mat-menu-item (click)="addPayme('venmo')" *ngIf="showPaymeLink('venmo')"><i class="fab fa-vimeo"></i>&nbsp;&nbsp;<span>Venmo</span></button>
                        <button mat-menu-item (click)="addPayme('cashapp')" *ngIf="showPaymeLink('cashapp')"><i class="fas fa-dollar-sign"></i>&nbsp;&nbsp;<span>Cash App</span></button>
                        <button mat-menu-item (click)="addPayme('bitcoin')" *ngIf="showPaymeLink('bitcoin')"><i class="fab fa-bitcoin"></i>&nbsp;&nbsp;<span>Bitcoin</span></button>
                        <button mat-menu-item (click)="addPayme('dogecoin')" *ngIf="showPaymeLink('dogecoin')"><img style="width: 14px;" src="/assets/icons/dogecoin_black.png">&nbsp;&nbsp;<span>Dogecoin</span></button>
                        <!-- <button mat-menu-item (click)="addPayme('ethereum')" *ngIf="showPaymeLink('ethereum')"><i class="fab fa-ethereum"></i>&nbsp;&nbsp;<span>Ethereum</span></button> -->
                    </mat-menu>
                    <div class="alert alert-danger save-alert">
                        <span *ngIf="serverErrorPayme">{{errorMsgPayme}}</span>
                    </div>
                    <!-- <button class="save-btn" (click)="onPaymeSubmit()" mat-raised-button [disabled]="!validationFormPayme.valid || formSubmittedPayme || !showPayme" type="button" color="primary">
                        <span>Save</span>
                    </button> -->
                </div>
            </form>
        </div>
    </div>
</div>