import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/*External Modules */
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AgmCoreModule } from '@agm/core';

/*App components */
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { PassthroughComponent } from './passthrough/passthrough.component';
import { LayoutService } from './services/layout.service';
import { AuthService } from './services/auth.service';
import { LoginModule } from './login/login.module';
import { EditModule } from './edit/edit.module';
import { LayoutModule } from './layout/layout.module';
import { UserService } from './services/user.service';
import { ImageUploaderService } from './services/image-uploader.service';
import { ImageModule } from './image/image.module';
import { MenuService } from './services/menu.service';
import { RestEditModule } from './rest-edit/rest-edit.module';
import { RestLayoutModule } from './rest-layout/rest-layout.module';
import { PrivacyComponent } from './official/privacy/privacy.component';
import { TermsComponent } from './official/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    PassthroughComponent,
    TermsComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBP5pTJBjFFDRgeUEqzAjtIuH18bIK7kc0',
      libraries: ["places", "geometry"]
    }),
    BrowserAnimationsModule,
    LoginModule,
    EditModule,
    LayoutModule,
    ImageModule,
    RestEditModule,
    RestLayoutModule
  ],
  providers: [AuthService, LayoutService, UserService, ImageUploaderService, MenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }
