import { Component, NgModule, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public validationFormEmail: FormGroup;
  public validationFormSignup: FormGroup;
  public showLogin:boolean = true;
  public formSubmitted:boolean = false;
  public showInfo:boolean = false;
  public serverError:boolean = false;
  public errorMsg:string = '';

  constructor(
    private formBuilder: FormBuilder,
    private parentRouter: Router,
    private authService: AuthService,
    private db: AngularFirestore,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit Login")
    this.buildForm()
  }

  buildForm() {
    this.validationFormEmail = this.formBuilder.group({
      requiredEmail: this.formBuilder.control(null, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      requiredPassword: this.formBuilder.control(null, [Validators.required])
    })
    this.validationFormSignup = this.formBuilder.group({
      registerEmail: this.formBuilder.control(null, [Validators.required, Validators.email]),
      registerName: this.formBuilder.control(null, [Validators.required]),
      requiredPassword: this.formBuilder.control(null, [Validators.required]),
      requiredTerms: this.formBuilder.control(null, [Validators.requiredTrue]),  			
      restaurantCheck: this.formBuilder.control(null, []),  			
      restaurantName: this.formBuilder.control(null, []),  			
      }, //{validator: this.checkPasswords}
    );
  }

  toggleForm(){
    this.showLogin = !this.showLogin;
  }

  loginByEmailClick() {
    console.log("Login by Email");
  }

  check() {
    console.log(

    )
  }
  
  registerByEmailClick() {
    let email:string = this.validationFormSignup.get('registerEmail').value.trim();
    let pass:string = this.validationFormSignup.get('requiredPassword').value;
    let name:string = this.validationFormSignup.get('registerName').value.trim();
    // console.log();
    console.log("Register by Email",email,pass);
    this.authService.createUserEmail(email,pass).then(user => {
      console.log("Successfully Created User");
      console.dir(user);
      console.log(user.user,user.user.uid)
      if(this.validationFormSignup.get('restaurantCheck').value) {
        let rName:string = this.validationFormSignup.get('restaurantName') ? this.validationFormSignup.get('restaurantName').value.trim() : '';
        this.userService.createNewRestaurantRef(user.user.uid,email,name,rName).then(result => {
          console.log('User Ref RESTAURANT CREATED')
          this.parentRouter.navigate(['/menu/edit']);
        }).catch(error => {
          console.log('User Ref Creation Unsucessful',error)
        })
      } else {
        this.userService.createNewUserRef(user.user.uid,email,name).then(result => {
          console.log('User Ref CREATED')
          this.parentRouter.navigate(['/me/edit']);
        }).catch(error => {
          console.log('User Ref Creation Unsucessful',error)
        })
      }
    })
  }

  onLogin() {
    this.formSubmitted = true;
    // this.showInfo = false;

    this.authService.loginWithEmail(this.validationFormEmail.get('requiredEmail').value.trim(), this.validationFormEmail.get('requiredPassword').value)
      .then(user => {
        console.log("Login Component: Successfully logged in!",user);
        // console.dir(user);
        this.authService.isLoggedIn = true;
        this.authService.uid = user.user.uid;
        // if (this.authService.reAuthRequested) {
        // 	this.authService.reAuthRequested = false;
        // 	this.parentRouter.navigate(['/app/user-profile-edit'])
        // } else if (this.authService.reAuthReturnURL && this.authService.reAuthReturnURL != "") {
        // 	this.parentRouter.navigateByUrl(this.authService.reAuthReturnURL);
        // } else {
          console.log('checkRest',this.userService.checkRestaurantUser());
          this.userService.checkRestaurantUser().subscribe(result => {
            console.log('chceck restaurant user',result)
            if (result) {
              this.parentRouter.navigate(['/menu/edit']);
            } else {
              this.parentRouter.navigate(['/me/edit']);
            }
          })
          // this.parentRouter.navigate(['/me']);
        // }
      })
      .catch(error => {
        var errorCode = error.name;
        var errorMessage = this.authService.parseServerError(error);
        this.formSubmitted = false;
        this.serverError = true;
        this.errorMsg = errorMessage;
        console.log("LOGIN:EMAIL: Error: " + error.message);
      });
  }

}
