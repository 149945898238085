import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxVcardModule } from "ngx-vcard";
import { LayoutComponent } from './layout.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';




@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    NgxVcardModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatSnackBarModule,
  ]
})
export class LayoutModule { }
