import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { InvitecodeService } from './invitecode.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(    
    private db: AngularFirestore,
    private inviteCodeService:InvitecodeService,
    private authService:AuthService,
  ) { }
}
