import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VCard, VCardEncoding, VCardFormatter } from 'ngx-vcard';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

import * as _ from 'lodash';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  private userID:string;
  private userSub:Subscription;

  private defaultData:any = {
    first_name:'',
    last_name:'',
    phone:'',
    email:'',
    title:'',
    company:'',
    vc_settings: {
      enable_phone:false,
      enable_email:false,
      enable_company:false,
      enable_title:false,

    },
    social_links:[],
    payme_links:[],
    photo_url:""
  }

  public data = this.defaultData;

  public showPayments:boolean = false;

  public vCardEncoding: typeof VCardEncoding = VCardEncoding;
  public vCard: VCard = { name: { firstNames: 'John', lastNames: 'Doe' } };
  public vCardString = VCardFormatter.getVCardAsString(this.vCard)

  constructor(
    private route: ActivatedRoute,
    public userService: UserService,
    public authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    this.userID = this.route.snapshot.params['id'];

    this.userSub = this.userService.getUserInfoByCustID(this.userID).subscribe(userInfo => {
      console.log("User Info",userInfo);
      if (userInfo) {
        this.populateData(userInfo);
      } else {
        console.log('custom id check was null check by dbid');
        this.userService.getUserInfoByID(this.userID).subscribe(userInfo => {
          console.log(userInfo);
          if (userInfo) {
            this.populateData(userInfo);
          } else {
            console.log('invalid link, redirect')
            this.router.navigate([`login`], { });
          }
        })
      }
      
    })
    
  }

  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  populateData(userInfo) {
    this.data = _.merge(this.data, userInfo);
    for(let payme of this.data.payme_links) {
      console.log(payme)
      if (payme.visible) {
        this.showPayments = true;
      }
    }
    console.log("User Data After Merge",this.data);
    this.vCardSetInfo(userInfo);
  }

  editProfile() {
    this.router.navigate([`me/edit`], { });
  }

  goTheme() {
    console.log('goTheme clicked!');
    this.router.navigate([`me/edit/theme`], { });
  }

  isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  isAdminUser() {
    return this.authService.userID == 'TjOsx8BZNffPySCWnaZFeQwP6PO2';
  }

  signOut() {
    this.authService.logout().then(result => {
      // this.router.navigate(['login'])
    })
  }

  vCardSetInfo(data) {
    this.vCard = {
      name: {
        firstNames: data.first_name,
        lastNames: data.last_name
      },
      email: data.vc_settings.enable_email ? (data.email ? [data.email] : []) : [],
      telephone: data.vc_settings.enable_phone ? (data.phone ? [data.phone] : []) : [],
      organization: data.vc_settings.enable_company ? (data.company ? data.company : '') : '',
      title: data.vc_settings.enable_title ?(data.title ? data.title : '') : '',
      url: data.vc_settings.enable_website ?(data.website ? this.linkFormatter(data.website) : '') : '',
      photo: data.vc_settings.enable_image ?(data.photo_url ? data.photo_url : '') : ''

    }
    // data loaded, enable vcard creation btn
    this.vCardString = this.vCardToString();
  }

  vCardToString() {
    return VCardFormatter.getVCardAsString(this.vCard);
  }

  goToSocial(social) {
    console.log('goToSocial Clicked!',social)
    window.location.assign(`http://${this.getLink(social)}`);
  }

  linkFormatter(link) {
    if (!link.includes('https://') && !link.includes('http://')) {
      return `https://${link}`;
    } else if(!link.includes('http://')) {
      return `http://${link}`
    } else {
      return link;
    }
  }

  socialFormat(social) {
    if (social.link.includes(`.com`) || social.link.includes(`http`) || social.link.includes(`www.`)) {
      if (social.link.includes('https://')) {
        return social.link;
      } else if(!social.link.includes('http://')) {
        return social.link;
      } else {
        return social.link;
      }
    } else {
      return `https://${this.getLink(social)}`;
    } 
  }

  getLink(social) {
    if(social.type == 'instagram') {
      return `instagram.com/${social.link}`;
    } else if (social.type == 'snapchat'){
      return `snapchat.com/add/${social.link}`
    } else if (social.type == 'tiktok'){
      return `vt.tiktok.com/${social.link}`
    } else if (social.type == 'github'){
      return `github.com/${social.link}`
    } else if (social.type == 'angellist'){
      return `angellist.co/u/${social.link}`
    } else if (social.type == 'twitter'){
      return `twitter.com/${social.link}`
    } else if (social.type == 'reddit'){
      return `reddit.com/u/${social.link}`
    } else if (social.type == 'facebook'){
      return `facebook.com/${social.link}`
    } else if (social.type == 'youtube'){
      return `youtube.com/c/${social.link}`
    } else if (social.type == 'vimeo'){
      return `vimeo.com/${social.link}`
    } else if (social.type == 'twitch'){
      return `twitch.com/${social.link}`
    } else if (social.type == 'behance'){
      return `behance.com/${social.link}`
    } else if (social.type == 'deviantart'){
      return `deviantart.com/${social.link}`
    } else if (social.type == 'untappd'){
      return `untappd.com/user/${social.link}`
    } else if (social.type == 'paypal'){
      return `http://paypal.me/${social.link}`
    } else if (social.type == 'cashapp'){
      return `http://cash.app/${social.link}`
    } else if (social.type == 'soundcloud'){
      return `soundcloud.com/${social.link}`
    } else if (social.type == 'venmo'){
      return `${social.link}`
    } else if (social.type == 'linkedin'){
      if(social.link.includes('linkedin')) {
        return this.linkFormatter(social.link)
      } else {
        return `linkedin.com/in/${social.link}`
      }
    } else if (social.type == 'spotify'){
      return this.linkFormatter(social.link)
    } else if (social.type == 'stack-overflow'){
      return this.linkFormatter(social.link)
    } else if (social.type == 'bandcamp'){
      return this.linkFormatter(social.link)
    } else {
      return this.linkFormatter(social.link);
    }
  }

  copyWalletAddress(link) {
    this.copyToClipboard(link);
    this.openSnackBar('Wallet address copied to clipboard','');
  }

  getTypeToString(type) {
    if(type == 'instagram') {
      return 'Instagram';
    }
  }

  getIcon(type) {
    if(type == 'instagram') {
      'instagram';
    }
  }

  copyToClipboard(textToCopy) {
    var textArea;

    console.log('link check:',textToCopy)
  
    function isOS() {
      //can use a better detection logic here
      return navigator.userAgent.match(/ipad|iphone/i);
    }
  
    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }
  
    function selectText() {
      var range, selection;
  
      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }
  
    function copyTo() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  
    createTextArea(textToCopy);
    selectText();
    copyTo();
  }

}
